import { useRef, useState } from "react";
import Footer from "../../../Components/Footer";
import { campaignClient } from "../../../api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import SignaturePad from "react-signature-canvas";
import {
  canadianPostalCodeRegex,
  emailRegex,
  looseNorthAmericanPhoneRegex,
  stringRegex,
} from "../../../helpers/utils";
import Header from "../../../Components/Header";
import { renderProvincesDropdown } from "../../../helpers/provinces";
import PopUpCustom from "../../../Components/PopUpCustom";
import Release from "./Release";
import { b64toBlob } from "../../../helpers/utils";
import axios from "axios";
import Loading from "../../../Components/Loading";

type STQFormData = {
  firstName: string;
  lastName: string;
  email: string;
  address1: string;
  address2?: string;
  city: string;
  postal: string;
  province: string;
  answer: string;
  signUrl: string;
  phone: string;
  rules: boolean;
};

function STQ() {
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isModal, setIsModal] = useState(false);
  const [signImageUrl, setSignImageUrl] = useState<File>();
  const signCanvas = useRef() as React.MutableRefObject<any>;
  const { state } = useLocation();
  const { prizeName, winKey } = state;
  const navigate = useNavigate();
  const { sk } = useParams();
  const {
    register,
    handleSubmit,

    formState: { errors, isDirty, isSubmitting },
  } = useForm<STQFormData>();

  async function handleForm(data: STQFormData) {
    if (submitting || signCanvas?.current?.isEmpty()) {
      return;
    }

    setSubmitting(true);

    const uploadkey = await saveSignature(
      signCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    );

    if (!uploadkey) {
      setSubmitting(false);
      setErrorMessage("Looks like something went wrong. Please try again later.");
      return;
    }

    try {
      const {
        error,
        message,
        code,
        claimed,
        prizeName,
      }: {
        error: string;
        message: string;
        code: string;
        claimed: boolean;
        prizeName: string;
      } = await campaignClient.call("prizeClaim", {
        sessionKey: sk,
        winKey,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        address1: data.address1,
        address2: data.address2,
        city: data.city,
        postal: data.postal,
        province: data.province,
        answer: data.answer,
        signUrl: uploadkey,
        phone: data.phone,
        rules: data.rules,
      });
      if (claimed && prizeName) {
        const state = { prizeName };
        navigate("/portal/confirmation", { state });
      } else if (code) {
        setIsModal(true);
        setErrorMessage("Looks like something went wrong. Please try again later.");
      } else if (error && message) {
        setIsModal(true);
        setErrorMessage(message);
      }
    } catch {
      setIsModal(true);
      setErrorMessage("Looks like something went wrong. Please try again later.");
    }
    setSubmitting(false);
  }

  const getfileuploadurl = async (sessionKey: string, type: string) => {
    
    try {
      let { uploadUrl, key } = await campaignClient.call<{
        uploadUrl: string;
        key: string;
      }>("getFileUploadUrlForUser", {
        sessionKey,
        filename: "signature",
        contentType: ".jpg",
      });     

      return { uploadUrl, urlKey: key };
    } catch (e) {
      setSubmitting(false);

      console.log(e);
    }
  };

  const saveSignature = async (sign: any) => {
    setSubmitting(true);
    setSignImageUrl(sign);
    let sessionKey = sk;

    const res = await getfileuploadurl(sessionKey!, typeof sign);

    // handleUpload()

    if (sign && res?.uploadUrl) {
      const block = sign.split(";");
      const contentType = block[0].split(":")[1];
      const realData = block[1].split(",")[1];
      const blob = b64toBlob(realData, contentType);
      try {
        const data = await blob.arrayBuffer();
        axios
          .put(res.uploadUrl, data, {
            onUploadProgress: function (progressEvent) {
              // const percentCompleted = Math.round(
              //   (progressEvent.loaded * 100) / progressEvent.total
              // );
            },
            headers: {
              "Content-Type": typeof blob,
            },
          })
          .then(async (res) => {
            if (res.status === 200) {
            }
          })
          .catch((e) => {
            console.log(e);
          });
        setSubmitting(false);
      } catch {
        setSubmitting(false);

        console.log("error while uploading sign");
      }
      return res.urlKey;
    }
  };

  return (
    <div className="wrapper">
      {submitting && <Loading />}
      {isModal && errorMessage && <PopUpCustom setIsModal={setIsModal}>{errorMessage}</PopUpCustom>}
      <Header />
      <section className="section section--small-height">
        <div className="shell">
          <div className="section__inner">
            <div className="section__head">
              <span className="section__logo logo logo--large">
                <img src="/assets/images/svg/logo.svg" width="315" height="64" alt="" />
              </span>
              {/* /.section__logo */}
              <h2>Complete the skill-testing question</h2>

              <p>* Indicates required field</p>
            </div>
            {/* /.section__ head */}

            <div className="section__body">
              <div className="form">
                <form onSubmit={handleSubmit(handleForm)}>
                  <div className="form__body">
                    <div className="form__row">
                      <label htmlFor="firstName" className="form__label">
                        * First name
                      </label>

                      <div className="form__controls">
                        <input
                          {...register("firstName", {
                            required: {
                              value: true,
                              message: "Please enter your first name.",
                            },
                            pattern: {
                              value: stringRegex,
                              message: "Please enter a valid first name.",
                            },
                          })}
                          placeholder="First name"
                          className="field"
                        />
                      </div>
                      {errors.firstName && (
                        <p className="error-message">
                          <img
                            src="/assets/images/svg/exclamation-mark.svg"
                            title="Exclamation Mark"
                          />
                          {errors.firstName.message}
                        </p>
                      )}
                    </div>
                    {/* /.form__row */}

                    <div className="form__row">
                      <label htmlFor="lastName" className="form__label">
                        {"* Last name"}
                      </label>

                      <div className="form__controls">
                        <input
                          {...register("lastName", {
                            required: {
                              value: true,
                              message: "Please enter your last name.",
                            },
                            pattern: {
                              value: stringRegex,
                              message: "Please enter a valid last name.",
                            },
                          })}
                          placeholder="Last name"
                          className="field"
                        />
                      </div>
                      {errors.lastName && (
                        <p className="error-message">
                          <img
                            src="/assets/images/svg/exclamation-mark.svg"
                            title="Exclamation Mark"
                          />
                          {errors.lastName.message}
                        </p>
                      )}
                    </div>
                    {/* /.form__row */}

                    <div className="form__row">
                      <label htmlFor="email" className="form__label">
                        * Email{" "}
                      </label>

                      <div className="form__controls">
                        <input
                          {...register("email", {
                            required: {
                              value: true,
                              message: "Please enter your email.",
                            },
                            pattern: {
                              value: emailRegex,
                              message: "Please enter a valid email.",
                            },
                          })}
                          placeholder="Email"
                          className="field"
                        />
                      </div>
                      {errors.email && (
                        <p className="error-message">
                          <i className="fa fa-exclamation-circle"></i> {errors.email.message}
                        </p>
                      )}
                    </div>
                    {/* /.form__row */}

                    <div className="form__row">
                      <label htmlFor="address" className="form__label">
                        {"* Address line 1"}
                      </label>

                      <div className="form__controls">
                        <input
                          {...register("address1", {
                            required: {
                              value: true,
                              message: "Please enter your address line 1.",
                            },
                            minLength: {
                              value: 5,
                              message: "Please enter a valid address line 1.",
                            },
                            maxLength: {
                              value: 50,
                              message: "Please enter a valid address line 1.",
                            },
                          })}
                          placeholder="Address line 1"
                          className="field"
                          id="address"
                        />
                      </div>
                      {errors.address1 && (
                        <p className="error-message">
                          <img
                            src="/assets/images/svg/exclamation-mark.svg"
                            title="Exclamation Mark"
                          />
                          {errors.address1.message}
                        </p>
                      )}
                    </div>
                    {/* /.form__row */}

                    <div className="form__row">
                      <label htmlFor="address2" className="form__label">
                        Address line 2
                      </label>

                      <div className="form__controls">
                        <input
                          {...register("address2", {
                            min: {
                              value: 5,
                              message: "Please enter a valid address line 2.",
                            },
                            max: {
                              value: 50,
                              message: "Please enter a valid address line 2.",
                            },
                          })}
                          placeholder="Address line 2"
                          className="field"
                        />
                      </div>
                      {errors.address2 && (
                        <p className="error-message">
                          <img
                            src="/assets/images/svg/exclamation-mark.svg"
                            title="Exclamation Mark"
                          />
                          {errors.address2.message}
                        </p>
                      )}
                    </div>
                    {/* /.form__row */}

                    <div className="form__row">
                      <label htmlFor="city" className="form__label">
                        * City
                      </label>

                      <div className="form__controls">
                        <input
                          {...register("city", {
                            required: {
                              value: true,
                              message: "Please enter your city.",
                            },
                            pattern: {
                              value: stringRegex,
                              message: "Please enter a valid city.",
                            },
                          })}
                          placeholder="City"
                          className="field"
                          id="city"
                        />
                      </div>
                      {errors.city && (
                        <p className="error-message">
                          <img
                            src="/assets/images/svg/exclamation-mark.svg"
                            title="Exclamation Mark"
                          />
                          {errors.city.message}
                        </p>
                      )}
                    </div>
                    {/* /.form__row */}

                    <div className="form__row">
                      <label htmlFor="select2" className="form__label">
                        {"* Province / territory"}
                      </label>

                      <div className="form__controls">
                        <div className="select">
                          <select
                            id="select2"
                            style={{ color: "black" }}
                            {...register("province", {
                              required: {
                                value: true,
                                message: "Please select your province/territory",
                              },
                            })}>
                            <option value="">Select an option</option>
                            {renderProvincesDropdown()}
                          </select>
                        </div>
                        {errors.province && (
                          <p className="error-message">
                            <img
                              src="/assets/images/svg/exclamation-mark.svg"
                              title="Exclamation Mark"
                            />
                            {errors.province.message}
                          </p>
                        )}
                      </div>
                      {/* /.form__controls */}
                    </div>
                    {/* /.form__row */}

                    <div className="form__row">
                      <label htmlFor="postal" className="form__label">
                        * Postal code{" "}
                      </label>

                      <div className="form__controls">
                        <input
                          {...register("postal", {
                            required: {
                              value: true,
                              message: "Please enter your postal code.",
                            },
                            pattern: {
                              value: canadianPostalCodeRegex,
                              message: "Please enter a valid postal code.",
                            },
                          })}
                          placeholder="Postal code"
                          className="field"
                        />
                      </div>
                      {errors.postal && (
                        <p className="error-message">
                          <img
                            src="/assets/images/svg/exclamation-mark.svg"
                            title="Exclamation Mark"
                          />
                          {errors.postal.message}
                        </p>
                      )}
                    </div>
                    {/* /.form__row */}

                    <div className="form__row">
                      <label htmlFor="tel" className="form__label">
                        * Phone number{" "}
                      </label>

                      <div className="form__controls">
                        <input
                          {...register("phone", {
                            required: {
                              value: true,
                              message: "Please enter your phone number.",
                            },
                            pattern: {
                              value: looseNorthAmericanPhoneRegex,
                              message: "Please enter a valid phone number.",
                            },
                          })}
                          placeholder="Phone number"
                          className="field"
                        />
                      </div>
                      {errors.phone && (
                        <p className="error-message">
                          <img
                            src="/assets/images/svg/exclamation-mark.svg"
                            title="Exclamation Mark"
                          />
                          {errors.phone.message}
                        </p>
                      )}
                    </div>
                    {/* /.form__row */}

                    <>
                      <div className="form__group">
                        <div className="form__row">
                          <h5>Your prize</h5>
                          <p>{prizeName}</p>
                          <h5>Skill-testing question</h5>
                          <ul className="form__list">
                            <li>
                              <span>Multiply</span>
                              <span>10x10</span>
                            </li>
                            <li>
                              <span>Add</span>
                              <span>25</span>
                            </li>
                            <li>
                              <span>Divide by</span>
                              <span>5</span>
                            </li>
                            <li>
                              <span>Subtract</span>
                              <span>20</span>
                            </li>
                          </ul>
                          {/* /.form__list */}
                        </div>
                        {/* /.form__row */}
                        <div className="form__row">
                          <label htmlFor="answer" className="form__label">
                            * Enter your answer here
                          </label>
                          <div className="form__controls">
                            <input
                              placeholder="Enter your answer here"
                              className="field"
                              {...register("answer", {
                                required: {
                                  value: true,
                                  message: "Please answer the skill-testing question.",
                                },
                                pattern: {
                                  value: /^[5]$/,
                                  message: "You must correctly answer the skill-testing question.",
                                },
                              })}
                            />
                          </div>
                          {errors.answer && (
                            <p className="error-message">
                              <img
                                src="/assets/images/svg/exclamation-mark.svg"
                                title="Exclamation Mark"
                              />
                              {errors.answer.message}
                            </p>
                          )}
                          <p>
                            <small>
                              *Skill-testing question must be correctly answered in order to receive
                              the prize.
                            </small>
                          </p>
                        </div>
                        {/* /.form__row */}
                      </div>
                      {/* /.form__group */}
                      <div className="form__row">
                        <h4>Declaration and Release</h4>
                        <div className="checkboxSTQ">
                          <input
                            type="checkbox"
                            id="checkbox-terms"
                            {...register("rules", {
                              required: {
                                value: true,
                                message:
                                  "You must agree to the Declaration and Release in order to claim your prize.",
                              },
                            })}
                          />
                          <label htmlFor="checkbox-terms">
                            I agree to the Declaration and Release.
                          </label>
                        </div>
                        {errors.rules && (
                          <p className="error-message">
                            <img
                              src="/assets/images/svg/exclamation-mark.svg"
                              title="Exclamation Mark"
                            />
                            {errors.rules.message}
                          </p>
                        )}
                        <br />
                        {/* /.checkbox */}
                        <div className="form__text">
                          <Release />
                        </div>
                        {/* /.form__text */}
                      </div>
                      {/* /.form__row */}
                      <div className="form__row">
                        <label htmlFor="text" className="form__label">
                          * Please sign here
                        </label>
                        <div className="form__controls">
                          <SignaturePad
                            ref={signCanvas}
                            penColor="black"
                            canvasProps={{
                              className: "SigPad",
                              style: {
                                position: "relative",
                                display: "block",
                                zIndex: "9999",
                                width: "100%",
                                minHeight: "80px",
                                height: "150px",
                                background: "white",
                                border: "2px solid grey",

                                textDecoration: "none",
                              },
                              onClick: () => {
                                setSignImageUrl(
                                  signCanvas.current.getTrimmedCanvas().toDataURL("image/png")
                                );
                              },
                              onChange: () => {
                                setSignImageUrl(
                                  signCanvas.current.getTrimmedCanvas().toDataURL("image/png")
                                );
                              },
                              onTouchEnd: () => {
                                setSignImageUrl(
                                  signCanvas.current.getTrimmedCanvas().toDataURL("image/png")
                                );
                              },
                            }}
                          />
                        </div>
                        {/* /.form__controls */}

                        {signCanvas?.current?.isEmpty() && isDirty && (
                          <p className="error-message">
                            <img
                              src="/assets/images/svg/exclamation-mark.svg"
                              title="Exclamation Mark"
                            />
                            Please add your signature in the space provided.
                          </p>
                        )}
                      </div>
                    </>
                  </div>
                  {/* /.form__body */}

                  <div className="form__actions form__actions--alt">
                    <a
                      className="btn btn--transparent form__btn"
                      onClick={() => {
                        signCanvas.current.clear();
                      }}>
                      Clear signature
                    </a>

                    <button type="submit" className="btn form__btn" disabled={isSubmitting}>
                      Submit
                    </button>
                  </div>
                  {/* /.form__actions */}
                </form>
              </div>
              {/* /.form */}
            </div>
            {/* /.section__body */}
          </div>
          {/* /.section__inner */}
        </div>
        {/* /.shell */}
      </section>
      {/* /.section */}

      <Footer />
    </div>
  );
}

export default STQ;
