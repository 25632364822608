function Release() {
  return (
    <>
      <p style={{ textAlign: "center" }}>
        {" "}
        <strong>
          WESTJET SPIN-TO-WIN CONTEST
          <br />
          (the “Contest”){" "}
        </strong>
      </p>

      <ol>
        <li>
          <strong> PARTIES</strong>
        </li>
      </ol>
      <p>
        For the purposes of this Release, the term “Contest Entities” shall mean Employees,
        directors, officers, representatives, agents of WestJet, an Alberta partnership (the
        “Sponsor”), Wily Global Inc (“WILY”), Experiential Marketing Limited Partnership (“XMC”)
        (WILY and XMC, together, the “Administrators”) and each of their respective parent
        companies, affiliates, franchisees, subsidiaries, distributors, representatives, advertising
        and promotional agencies, agents, sponsors, and any entity that they contract with specific
        to the&nbsp;Contest, as well as the immediate family members, and any persons domiciled with
        any of the above (whether related or not).
      </p>
      <ol start={2}>
        <li>
          <strong> PRIZE(S)</strong>
        </li>
      </ol>
      <p>
        <strong>Grand Prizes: </strong>
      </p>
      <p>
        There are two (2) Grand Prizes (the “<strong>Grand Prizes</strong>”) available to be won in
        the Contest, one available during each <u>day</u> of the Contest Period (each, a “Contest
        Day”). Each Grand Prize consists of two (2) WestJet flight vouchers valued at approximately
        $4,240 to be used towards the base fare of a WestJet roundtrip flight for two (2) people.
        The flight vouchers are subject to the terms and conditions outlined in Schedule A.
      </p>
      <p>
        <strong>Secondary Prizes</strong>
      </p>
      <p>
        A total of fifty (50) Secondary Prizes (collectively the “<strong>Secondary Prizes</strong>
        ”) are available to be won in the Contest as described below. During each Contest Day, the
        following items are available to be won:
      </p>
      <p>
        Ten (10) $100.00 WestJet dollar prizes to be used towards WestJet flight(s). The WestJet
        dollars are subject to the terms and conditions outlined in Schedule B, up to a maximum of
        forty (40) over the Contest Period.
      </p>
      <p>
        Twenty (20) $50.00 WestJet dollar prizes to be used towards WestJet flight(s). The WestJet
        dollars are subject to the terms and conditions outlined in Schedule B, up to a maximum of
        forty (40) over the Contest Period.
      </p>
      <p>
        Twenty (20) $25.00 WestJet dollar prizes to be used towards WestJet flight(s). The WestJet
        dollars are subject to the terms and conditions outlined in Schedule B, up to a maximum of
        forty (40) over the Contest Period.
      </p>
      <p>
        <strong>Third-Tier Prizes</strong>
      </p>
      <p>
        <u>
          A total of three-thousand (3,000) Third-Tier Prizes (collectively the “
          <strong>Third-Tier Prizes”</strong>) are available to be won in the Contest Period as
          described below. During each Contest Day, the following item is available to be won:{" "}
        </u>
      </p>
      <p>
        Up to one thousand (1,000) pairs of “WestJet” sunglasses (approximate retail value: $CAD
        5.00) will be awarded per day to a maximum of one thousand (1,000) over the Contest
        Period.&nbsp;
      </p>
      <p>
        Up to one thousand (1,000) pairs of “WestJet” beach balls (approximate retail value: $CAD
        5.00) will be awarded per day to a maximum of one thousand (1,000) over the Contest
        Period.&nbsp;
      </p>
      <p>
        Up to one thousand (1,000) pairs of “WestJet” fanny packs (approximate retail value: $CAD
        5.00) will be awarded per day to a maximum of one thousand (1,000) over the Contest
        Period.&nbsp;
      </p>
      <p>
        Each Prize must be accepted as awarded and is not transferable, assignable, or convertible
        to cash (except as may be specifically permitted by Sponsor in its sole and absolute
        discretion). No substitutions except at Sponsor’s option. Sponsor reserves the right, in its
        sole and absolute discretion, to substitute a Prize or a component thereof with a prize of
        equal or greater value, including, without limitation, but at Sponsor’s sole and absolute
        discretion, a cash award.&nbsp;
      </p>
      <p>The following conditions apply to the Prizes:</p>
      <ul>
        <li>
          You are responsible for all costs not expressly described as being included as part of a
          Prize, such as any fee associated with the receipt and/or use of the Prize.
        </li>
        <li>
          You must sign the Sponsor’s release form (the “Release”) in order to receive and
          participate in a Grand or Secondary Prize.
        </li>
        <li>
          Any difference between approximate retail value and the actual value of the Prizes as
          received will not be awarded.
        </li>
        <li>Limit of one (1) Prize per person and per email address.</li>
        <li>
          <strong>Grand Prize: Flight Voucher Prize Terms and Conditions:</strong> The following
          additional terms and conditions apply to the Grand Prize: Flight voucher is valid towards
          new reservations booked at www.westjet.com only. Flight voucher cannot be redeemed with
          WestJet via telephone or through a travel agent, and cannot be combined with any other
          offer.
        </li>
      </ul>
      <ol start={3}>
        <li>
          <strong> COMPLIANCE WITH CONTEST RULES</strong>
        </li>
      </ol>
      <p>You, the undersigned, confirm and agree as follows:</p>
      <ul>
        <li>
          I have read, understand and complied with the Contest Rules and have agreed to be bound by
          them;
        </li>
        <li>
          I was the age of majorityin the province where I reside on the date I entered the Contest;
        </li>
        <li>I am a legal resident of Canada (excluding Quebec);</li>
        <li>
          I am not an employee, officer or director of any of the Contest Entities, nor am I
          domiciled with, or an immediate family member of, an employee, officer or director of the
          any of the Contest Entities; and
        </li>
        <li>
          I have correctly answered, unaided, the time-limited, mathematical skill-testing question
          attached to this Release.
        </li>
      </ul>
      <ol start={4}>
        <li>
          <strong> PUBLICITY RELEASE</strong>
        </li>
      </ol>
      <p>
        You, the undersigned, agree that your name, comments, voice, likeness, municipality of
        residence and picture may be published and used for advertising, marketing, public
        relations, and other promotional purposes by the Contest Entities and their direct and
        indirect affiliates and subsidiaries, without notice or any payment or other compensation to
        you.
      </p>
      <ol start={5}>
        <li>
          <strong> LIABILITY RELEASE AND INDEMNIFICATION </strong>
        </li>
      </ol>
      <p>
        For good and valuable consideration (including the awarding and receipt of the Prize), you,
        the undersigned, hereby release the Contest Entities, from any and all claims, actions,
        causes or action, complaints, suits, damages, costs, expenses, solicitor fees and
        liabilities of any kind (collectively “Claims”) including, without limitation, any Claims
        arising from any negligence of the Contest Entities, which you ever had, now have or
        hereafter will or may have, or which your heirs, administrators, executors, successors or
        assignees or any of them will or may have, for or by reason of any cause, matter or thing
        arising from or relating in any manner to, the Contest, your participation therein, the
        awarding of a Prize, your participation in the Prize, or your acceptance, possession, use or
        misuse of the Prize or the products you obtain as a result thereof, or (where applicable)
        you travelling to, preparing for, or participating in, any Contest or Prize related trip or
        activity, or the publicity rights you have granted hereunder, or any misrepresentation by
        you contained in this Release. You also agree to indemnify, defend, and hold harmless the
        Contest Entities from any and all Claims in respect of the foregoing. You understand that
        there may be serious risk of bodily injury, death, and/or property damage associated with
        the acceptance, possession, use and/or misuse of some prizes, and you voluntarily agree to
        assume these risks and to fully and completely release and hold harmless the Contest
        Entities from any and all liability for any such bodily injury, death, and/or property
        damage resulting therefrom.
      </p>
      <ol start={6}>
        <li>
          <strong> OTHER IMPORTANT TERMS</strong>
        </li>
      </ol>
      <p>
        You understand that you are required to pay any title, license, insurance, registration
        fees, or taxes associated with the Prize. You agree that any claim or dispute with respect
        to the Contest, the Prize, or this Agreement shall be governed by the laws of the Province
        of Alberta and the laws of Canada and any proceedings in relation to any such claim or
        dispute shall be resolved exclusively by courts of the Province of Alberta, Canada. You
        agree that if any portion of this Agreement is determined to be unenforceable by a court of
        law, all other parts of this Agreement shall remain in full force and effect, and that the
        conditions and covenants herein shall be binding on your heirs, administrators, executors,
        successors and assigns.
      </p>
      <ol start={7}>
        <li>
          <strong> ACCEPTANCE OF PRIZE</strong>
        </li>
      </ol>
      <p>
        You will accept the Prize as is. You understand that the Contest Entities have not made any
        warranty, representation or guarantee (including, but not limited to any warranty of
        merchantability or fitness for a particular purpose or use), whether written or verbal,
        express or implied, regarding the Prize, or any element thereof, or substitution therefore.
      </p>
      <p>
        You understand that this Release affects legal rights you may have. You understand that you
        have the opportunity to take this Release away and review it with your legal counsel for up
        to ten (10) days before signing (although you acknowledge that you must first in any event
        complete the skill testing question attached to this Release before a representative of the
        Administrator and have either done so, or hereby waive your right to do so).
      </p>
    </>
  );
}

export default Release;
