import { FunctionComponent } from "react";
import Confirmation from "../../../Components/Confirmation";
import { useLocation } from "react-router-dom";

interface STQConfirmationProps {}

const STQConfirmation: FunctionComponent<STQConfirmationProps> = ({}) => {
  const { state } = useLocation();
  const prizeName = state.prizeName;

  const prizes:{[key:string]: string} = {
    Flight: "One (1) WestJet Roundtrip Flight",
    WestJetDollars25: "$25 WestJet dollars",
    WestJetDollars50: "$50 WestJet dollars",
    WestJetDollars100: "100 WestJet dollars",
    WestJetFannyPack: "One (1) WestJet Fanny Pack",   
  };
  let body = "Please present the prize winner with their prize: " + prizes[prizeName];
  let title = "Prize claimed";
  if (prizeName === 'WestJetFannyPack') {
    title = "Prize awarded";
  } else {
    body =
      "The user will now receive a prize claim confirmation email. The prize will be awarded shortly.";
  }
  return (
    <Confirmation
      title={title}
      body={body}
      actionPath="/portal/prize-search"
      titleAction="Back to search"
    />
  );
};

export default STQConfirmation;
