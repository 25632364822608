import { Link, useNavigate } from "react-router-dom";
import disableBrowserHistory from "../../../Components/ComponentsUI/DisableBrowserBack";
import Footer from "../../../Components/ComponentsUI/Footer";
import { defaultStorageHelper } from "@spry/campaign-client";
import { useEffect } from "react";

export default function PrizeReveal() {
  disableBrowserHistory();
  const navigate = useNavigate();
  const prize = defaultStorageHelper.get("prize");
  const prizeName = prize?.split("-")[0];
  const prizeCode = prize?.split("-")[1];
  useEffect(() => {
    if (!prizeName || !prizeCode) navigate("/");
  }, []);
  const ClearStorage = () => {
    defaultStorageHelper.remove("prize");
    //defaultStorageHelper.remove("SPRYSessionKey");
  };

  return (
    <div className="wrapperUI">
      <div className="mainUi">
        <div className="shell">
          <section className="sectionUI sectionUI--text">
            <div className="sectionUI__head">
              <img src="/assets/images/ui/svg/logo.svg" width="511" height="104" alt="" />
            </div>

            <div className="sectionUI__body">
              <h2 className="h1">Congrats!</h2>

              <h3 className="h2">
                You’ve won:
                <br />
                {prizeName}
              </h3>

              <h4 className="h2">
                Present the prize code below to your WestJet ambassador to claim your prize.
              </h4>

              <p className="text-large-spacing">{prizeCode}</p>

              <Link to="/" className="btnUI" onClick={ClearStorage}>
                Done
              </Link>
            </div>
          </section>
          {/* /.section */}
        </div>
        {/* /.shell */}
      </div>
      {/* /.main */}

      <Footer />
    </div>
  );
}
