import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { emailRegex, stringRegex } from "../../../helpers/regexes";
import { useState } from "react";
import { publicCampaignClient, getSessionClient, setSessionClient } from "../../../api";
import Loading from "../../../Components/ComponentsUI/Loading";
import ModalUserMessage from "../../../Components/ComponentsUI/PopUp";
import Footer from "../../../Components/ComponentsUI/Footer";

import PopUpModal from "../../../Components/ComponentsUI/PopUpModal";
import Rules from "../../../Components/ComponentsUI/Rules";

type RegisterFormData = {
  firstName: string;
  lastName: string;
  email: string;
  email2: string;
  text: string;
  rules: boolean;
  optin: boolean;
};

export default function Registration() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<RegisterFormData>();

  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [submissionError, setSubmissionError] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [isModalMessage, setIsModalMessage] = useState(false);

  async function handleRegister(data: RegisterFormData) {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    setSubmissionError(false);
    try {
      const { sessionKey, error, message, code } = await getSessionClient()
        .createParticipantBuilder()
        .setFirstName(data.firstName)
        .setLastName(data.lastName)
        .setEmail(data.email)
        .setPreferredLanguage("en")
        .addMetadata({
          rules: data.rules,
          optin1: data.optin,
          question: data.text,
        })
        .call<{ sessionKey?: string; error?: string; message?: string; code?: string }>("register");
      if (sessionKey) {
        setSessionClient(publicCampaignClient.recoverSession(sessionKey));

        navigate("/spin-wheel");
      } else if (code === "email_resubmitted") {
        setIsModalMessage(true);
        setErrorMessage("Email has already been registered.");
        reset({ email: "", email2: "" });
      } else if (error && message) {
        setIsModalMessage(true);
        setErrorMessage(message);
        setSubmissionError(true);
      } else if (error) {
        setErrorMessage("Sorry something seems to have gone wrong. Please try again.");
        setSubmissionError(true);
      }
    } catch {
      setErrorMessage("Sorry something seems to have gone wrong. Please try again.");
      setSubmissionError(true);
    }
    setSubmitting(false);
  }

  return (
    <>
      <div className="wrapperUI">
        {}
        {isModalMessage && errorMessage && (
          <ModalUserMessage setIsModal={setIsModalMessage}>{errorMessage}</ModalUserMessage>
        )}
        {submitting && <Loading />}
        {isModal && (
          <PopUpModal setIsModal={setIsModal}>
            <Rules />
          </PopUpModal>
        )}

        <div className="mainUi">
          <div className="shellUi">
            <section className="sectionUI">
              <div className="sectionUI__head">
                <img src="/assets/images/ui/svg/logo.svg" width="511" height="104" alt="" />
              </div>
              {/* /.sectionUI__head */}

              <div className="formUI">
                <form onSubmit={handleSubmit(handleRegister)} autoComplete="false">
                  <div className="formUI__head">
                    <h2>Sign up below</h2>

                    <p>* Indicates required field</p>
                  </div>
                  {/* /.formUI__head */}

                  <div className="formUI__body">
                    <div className="formUI__row">
                      <label className="formUI__label">* First name</label>
                      {/* /.formUI__label */}

                      <div className="formUI__controls">
                        <input
                          {...register("firstName", {
                            required: {
                              value: true,
                              message: "Please enter your first name.",
                            },
                            pattern: {
                              value: stringRegex,
                              message: "Please enter a valid first name.",
                            },
                          })}
                          className="fieldUI"
                          placeholder="First name"
                        />
                      </div>
                      {/* /.formUI__controls */}
                      {errors.firstName && (
                        <p className="error-message">
                          <img
                            src="/assets/images/svg/exclamation-mark.svg"
                            title="Exclamation Mark"
                          />
                          {errors.firstName.message}
                        </p>
                      )}
                    </div>
                    {/* /.formUI__row */}

                    <div className="formUI__row">
                      <label className="formUI__label">* Last name</label>
                      {/* /.formUI__label */}

                      <div className="formUI__controls">
                        <input
                          {...register("lastName", {
                            required: {
                              value: true,
                              message: "Please enter your last name.",
                            },
                            pattern: {
                              value: stringRegex,
                              message: "Please enter a valid last name.",
                            },
                          })}
                          className="fieldUI"
                          placeholder="Last name"
                        />
                      </div>
                      {/* /.formUI__controls */}
                      {errors.lastName && (
                        <p className="error-message">
                          <img
                            src="/assets/images/svg/exclamation-mark.svg"
                            title="Exclamation Mark"
                          />
                          {errors.lastName.message}
                        </p>
                      )}
                    </div>
                    {/* /.formUI__row */}

                    <div className="formUI__row">
                      <label className="formUI__label">* Email</label>
                      {/* /.formUI__label */}

                      <div className="formUI__controls">
                        <input
                          {...register("email", {
                            required: {
                              value: true,
                              message: "Please enter your email.",
                            },
                            pattern: {
                              value: emailRegex,
                              message: "Please enter a valid email.",
                            },
                          })}
                          className="fieldUI"
                          placeholder="Email"
                        />
                      </div>
                      {errors.email && (
                        <p className="error-message">
                          <img
                            src="/assets/images/svg/exclamation-mark.svg"
                            title="Exclamation Mark"
                          />
                          {errors.email.message}
                        </p>
                      )}
                    </div>
                    {/* /.formUI__row */}

                    <div className="formUI__row">
                      <label className="formUI__label">* Confirm email</label>
                      {/* /.formUI__label */}

                      <div className="formUI__controls">
                        <input
                          {...register("email2", {
                            required: {
                              value: true,
                              message: "Please confirm your email.",
                            },
                            validate: (val: string) => {
                              if (watch("email") != val) {
                                return "Please make sure your email matches. ";
                              }
                            },
                          })}
                          className="fieldUI"
                          placeholder="Confirm email"
                        />
                      </div>
                      {errors.email2 && (
                        <p className="error-message">
                          <img
                            src="/assets/images/svg/exclamation-mark.svg"
                            title="Exclamation Mark"
                          />
                          {errors.email2.message}
                        </p>
                      )}
                    </div>
                    {/* /.formUI__row */}

                    <div className="formUI__row">
                      <label className="formUI__label">
                        * What is WestJet the official airline for in your life?
                      </label>
                      {/* /.formUI__label */}

                      <div className="formUI__controls">
                        <input
                          {...register("text", {
                            required: {
                              value: true,
                              message: "Please answer the question.",
                            },
                            maxLength: {
                              value: 100,
                              message: "Please answer the question.",
                            },
                            minLength: {
                              value: 3,
                              message: "Please answer the question.",
                            },
                          })}
                          className="fieldUI"
                          placeholder="ie. beach vacations"
                        />
                      </div>
                      {errors.text && (
                        <p className="error-message">
                          <img
                            src="/assets/images/svg/exclamation-mark.svg"
                            title="Exclamation Mark"
                          />
                          {errors.text.message}
                        </p>
                      )}
                    </div>
                    {/* /.formUI__row */}

                    <div className="formUI__row">
                      <div className="checkbox">
                        <input
                          {...register("rules", {
                            required: {
                              value: true,
                              message: "You must agree to the contest rules to enter. ",
                            },
                          })}
                          type="checkbox"
                          id="terms"
                        />

                        <label htmlFor="terms">
                          {" "}
                          {"* " + "I agree to the"}{" "}
                          <a onClick={() => setIsModal(true)}> Contest Rules </a>
                          including the release of liability. I am a Canadian resident and have
                          reached the age of majority in my jurisdiction of residence.
                        </label>
                      </div>
                      {errors.rules && (
                        <p className="error-message">
                          <img
                            src="/assets/images/svg/exclamation-mark.svg"
                            title="Exclamation Mark"
                          />
                          {errors.rules.message}
                        </p>
                      )}
                    </div>
                    {/* /.formUI__row */}

                    <div className="formUI__row">
                      <div className="checkbox">
                        <input {...register("optin")} type="checkbox" id="optin" />

                        <label htmlFor="optin">
                          {" "}
                          I would like to receive communications and offers from WestJet. You can
                          withdraw your consent at any time. Visit our{" "}
                          <a
                            href="https://www.westjet.com/en-ca/legal/privacy-policy"
                            target="_blank">
                            Privacy Policy
                          </a>{" "}
                          for details.{" "}
                        </label>
                      </div>
                      {/* /.checkbox */}
                    </div>
                    {/* /.formUI__row */}
                  </div>
                  {/* /.formUI__body */}

                  <div className="formUI__actions">
                    <button type="submit" className="btnUI formUI__btn">
                      Submit
                    </button>
                  </div>
                  {/* /.formUI__actions */}
                </form>
              </div>
              {/* /.form */}
            </section>
            {/* /.section */}
          </div>
          {/* /.shell */}
        </div>
        {/* /.main */}

        <Footer />
      </div>
    </>
  );
}
