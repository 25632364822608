import { defaultStorageHelper } from "@spry/campaign-client";
import { FunctionComponent, useEffect, useState } from "react";

import PopUpModal from "../PopUpModal";
import Rules from "../Rules";
import { Link } from "react-router-dom";

interface FooterProps {
  openModal?: boolean;
}

const Footer: FunctionComponent<FooterProps> = ({ openModal }) => {
  const [isModal, setIsModal] = useState(openModal ? openModal : false);

  return (
    <footer className="footerUI">
      {isModal && (
        <PopUpModal setIsModal={setIsModal}>
          <Rules />
        </PopUpModal>
      )}
      <div className="shellUi">
        <div className="footerUI__inner">
          <nav className="footerUI__nav">
            <ul>
              <li>
                <a onClick={() => setIsModal(true)}>Contest Rules</a>
              </li>

              <li>
                <Link to="https://www.westjet.com/en-ca/legal/privacy-policy" target="_blank">
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </nav>
          {/* /.footerUI__nav */}

          <p className="footerUI__copyright">© WestJet. All rights reserved.</p>
          {/* /.footerUI__copyright */}

          {/* /.copyright */}
        </div>
        {/* /.footer__inner */}
      </div>
      {/* /.shell */}
    </footer>
  );
};

export default Footer;
