import { FunctionComponent } from "react"

import Modal from "react-modal"

interface ModalUserMessageProps {
  setIsModal: (value: boolean) => void
  children?: string | JSX.Element | JSX.Element[]
}

const ModalUserMessage: FunctionComponent<ModalUserMessageProps> = ({
  setIsModal,
  children,
}) => {

  return (
    <Modal
      isOpen
      contentLabel="Returning user"
      ariaHideApp={false}
      className="popupUserMessage"
      portalClassName="modal"
      onAfterOpen={() => (document.body.style.overflow = "hidden")}
      onAfterClose={() => (document.body.style.overflow = "unset")}
      style={{
        overlay: {
          zIndex: 1043,
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0,0,0,0.5)",
          //   -webkit-backface-visibility: hidden;
        },
      }}>
      <div
        style={{
          textAlign: "end",
          padding: "6rem 5rem 5rem",
          backgroundColor: "#fff",
          color: "black",
          borderRadius: "1rem",
          maxWidth: "500px",
          overflowWrap: "anywhere",
          textAlignLast: "center",
        }}>
        {children}
        <div style={{ marginTop: "5rem" }}></div>
        <button
          onClick={() => {
            setIsModal(false)
          }}
          className="btn grey">
          <span>Ok</span>
        </button>
      </div>
    </Modal>
  )
}

export default ModalUserMessage
