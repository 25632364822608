import { useState, useEffect } from "react";

import { campaignClient } from "../../../api";

import Header from "../../../Components/Header";
import Footer from "../../../Components/Footer";
import { useForm } from "react-hook-form";
import PopUpCustom from "../../../Components/PopUpCustom";
import { useStores } from "../../../stores";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../../../Components/Loading";

type InventoryData = {
  flight: number;
  dollars25: number;
  dollars50: number;
  dollars100: number;
};

function InventorySettings() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [submissionError, setSubmissionError] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(state.selectedDate);
  const [inventoryData, setInventoryData] = useState<InventoryData>();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const user = useStores().authStore.user.name;
  const {
    register,
    handleSubmit,
    setValue,

    setError,
    clearErrors,
    formState: { errors },
  } = useForm<InventoryData>({
    defaultValues: {
      flight: state.inventoryData.flight,
      dollars25: state.inventoryData.dollars25,
      dollars50: state.inventoryData.dollars50,
      dollars100: state.inventoryData.dollars100,
    },
  });

  useEffect(() => {
    !!selectedDate && clearErrors("dollars25");
    /// setInventoryData({ flight: 0, dollars: 0, voucher: 0 })
  }, [selectedDate]);

  function handlePrizeSearch(data: InventoryData) {
    if (!selectedDate) {
      setError("dollars25", { type: "manual", message: "Please select a date." });
      return;
    }
    setInventoryData(data);

    setIsSubmitted(true);
  }

  async function confirmedPrizeSearch() {
    if (submitting) {
      return;
    }

    setSubmitting(true);
    setSubmissionError(false);

    try {
      const {
        result,
        error,
        code,
        message,
      }: {
        result: boolean;
        error: string;
        code: string;
        message: string;
      } = await campaignClient.call("addInventory", {
        selectedDate,
        email: user,
        quantity: {
          flight: inventoryData?.flight,
          dollars25: inventoryData?.dollars25,
          dollars50: inventoryData?.dollars50,
          dollars100: inventoryData?.dollars100,
        },
      });
      if (result) {
        const state = { date: selectedDate };
        navigate("/portal/inventory-conf", { state });
      } else if (error && message) {
        setErrorMessage(message);
        setIsModal(true);
      } else if (error === "prizeCount is invalid") {
        setErrorMessage("Something went wrong, please try again.");
        setIsModal(true);
      } else if (error || code) {
        setErrorMessage("Something went wrong, please try again later.");
        setIsModal(true);
      }
    } catch {
      setIsModal(true);
      setErrorMessage("Something went wrong, please try again.");
    }
    setInventoryData({
      flight: 0,
      dollars25: 0,
      dollars50: 0,
      dollars100: 0,
    });
    setIsSubmitted(false);
    setSubmitting(false);
  }

  return (
    <div className="wrapper">
      {isModal && errorMessage && <PopUpCustom setIsModal={setIsModal}>{errorMessage}</PopUpCustom>}
      <Header isPrizeInventory={true} />
      {submitting && <Loading />}
      <section className="section">
        {isSubmitted ? (
          <div className="shell">
            <div className="section__inner">
              <div className="section__head">
                <h2>Confirm the prize inventory</h2>
                <p>Please review the info below before confirming the prizes for the event.</p>
              </div>
              {/* /.section__ head */}
              <div className="section__body">
                <div className="list-detail list-detail--alt">
                  <ul>
                    <li>
                      <strong>Date</strong>
                      <span>{selectedDate}</span>
                    </li>
                  </ul>
                </div>
                {/* /.list-detail */}
                <div className="table">
                  <table>
                    <thead>
                      <tr>
                        <th>Prize type</th>
                        <th>Qty</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>WestJet Flight</td>
                        <td>{inventoryData?.flight}</td>
                      </tr>
                      <tr>
                        <td>$25 WestJet Dollars</td>
                        <td>{inventoryData?.dollars25}</td>
                      </tr>
                      <tr>
                        <td>$50 WestJet Dollars</td>
                        <td>{inventoryData?.dollars50}</td>
                      </tr>
                      <tr>
                        <td>$100 WestJet Dollars</td>
                        <td>{inventoryData?.dollars100}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* /.table */}
              </div>
              {/* /.section__body */}
              <div className="section__actions">
                <a onClick={confirmedPrizeSearch} className="btn">
                  Submit
                </a>
                <a
                  onClick={() => {
                    setInventoryData({
                      flight: 0,
                      dollars25: 0,
                      dollars50: 0,
                      dollars100: 0,
                    });
                    setIsSubmitted(false);
                  }}
                  className="btn btn--transparent">
                  Back
                </a>
              </div>
              {/* /.section__actions section-actions-/-block */}
            </div>
            {/* /.section__inner */}
          </div>
        ) : (
          <div className="shell">
            <div className="section__inner">
              <div className="section__head">
                <h2>Set the prize inventory for your event</h2>
                <p>Select the date then set the quantity for each prize type.</p>
              </div>
              {/* /.section__ head */}
              <div className="section__body">
                <div className="form form--inventory">
                  <form onSubmit={handleSubmit(handlePrizeSearch)} method="post">
                    <div className="form__body">
                      <div className="form__row">
                        <label htmlFor="select" className="form__label">
                          Date
                        </label>
                        <div className="form__controls">
                          <div className="form__row form__row--flex">
                            <input className="field " value={selectedDate} disabled={true} />
                          </div>
                          {/* /.select */}
                        </div>
                        {/* /.form__controls */}
                      </div>
                      {/* /.form__row */}

                      <div className="form__row form__row--flex">
                        <label htmlFor="input2" className="form__label form__label--medium">
                          WestJet Flight
                        </label>
                        <div className="form__controls">
                          <input
                            type="number"
                            className="field field--small"
                            placeholder="Qty"
                            {...register("flight", {
                              required: { value: true, message: "Please enter number" },
                              min: {
                                value: 0,
                                message: "Minimum number is 0",
                              },
                              max: {
                                value: 1,
                                message: "Maximum number is 1",
                              },
                            })}
                            onChange={(e) => {
                              setValue("flight", +e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      {errors.flight && (
                        <p className="error-message">
                          <img
                            src="/assets/images/svg/exclamation-mark.svg"
                            title="Exclamation Mark"
                          />
                          {errors.flight.message}
                        </p>
                      )}
                      {/* /.form__row */}
                      <div className="form__row form__row--flex">
                        <label htmlFor="input3" className="form__label form__label--medium">
                          $25 WestJet Dollars
                        </label>
                        <div className="form__controls">
                          <input
                            type="number"
                            className="field field--small"
                            placeholder="Qty"
                            {...register("dollars25", {
                              required: { value: true, message: "Please enter number" },
                              min: {
                                value: 0,
                                message: "Minimum number for 25 WestJet Dollars is 0",
                              },
                              max: {
                                value: 4,
                                message: "Maximum number for 25 WestJet Dollars is 4",
                              },
                            })}
                            onChange={(e) => {
                              setValue("dollars25", +e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      {errors.dollars25 && (
                        <p className="error-message">
                          <img
                            src="/assets/images/svg/exclamation-mark.svg"
                            title="Exclamation Mark"
                          />
                          {errors.dollars25.message}
                        </p>
                      )}
                      {/* /.form__row */}
                      <div className="form__row form__row--flex">
                        <label htmlFor="input4" className="form__label form__label--medium">
                          $50 WestJet Dollars
                        </label>
                        <div className="form__controls">
                          <input
                            className="field field--small"
                            placeholder="Qty"
                            type="number"
                            {...register("dollars50", {
                              required: { value: true, message: "Please enter number" },
                              min: {
                                value: 0,
                                message: "Minimum number for 50 WestJet Dollars is 0",
                              },
                              max: {
                                value: 8,
                                message: "Maximum number for 50 WestJet Dollars is 8",
                              },
                            })}
                            onChange={(e) => {
                              setValue("dollars50", +e.target.value);
                            }}
                          />
                        </div>
                        {/* /.form__controls */}
                      </div>
                      {errors.dollars25 && (
                        <p className="error-message">
                          <img
                            src="/assets/images/svg/exclamation-mark.svg"
                            title="Exclamation Mark"
                          />
                          {errors.dollars25.message}
                        </p>
                      )}
                      {/* /.form__row */}
                      <div className="form__row form__row--flex">
                        <label htmlFor="input4" className="form__label form__label--medium">
                          $100 WestJet Dollars
                        </label>
                        <div className="form__controls">
                          <input
                            className="field field--small"
                            placeholder="Qty"
                            type="number"
                            {...register("dollars100", {
                              required: { value: true, message: "Please enter number" },
                              min: {
                                value: 0,
                                message: "Minimum number for 100 WestJet Dollars is 0",
                              },
                              max: {
                                value: 8,
                                message: "Maximum number for 100 WestJet Dollars is 8",
                              },
                            })}
                            onChange={(e) => {
                              setValue("dollars100", +e.target.value);
                            }}
                          />
                        </div>
                        {/* /.form__controls */}
                      </div>
                      {errors.dollars100 && (
                        <p className="error-message">
                          <img
                            src="/assets/images/svg/exclamation-mark.svg"
                            title="Exclamation Mark"
                          />
                          {errors.dollars100.message}
                        </p>
                      )}
                    </div>
                    {/* /.form__body */}
                    <div className="form__actions" style={{ justifyContent: "space-between" }}>
                      <button type="submit" className="btn form__btn" style={{ width: "100px" }}>
                        Submit
                      </button>
                      <button
                        onClick={() => navigate("/portal/inventory-prize")}
                        className="btn btn--transparent form__btn"
                        style={{ width: "50px" }}>
                        Cancel
                      </button>
                    </div>
                    {/* /.form__actions */}
                  </form>
                </div>
                {/* /.form */}
              </div>
              {/* /.section__body */}
            </div>
            {/* /.section__inner */}
          </div>
        )}
        {/* /.shell */}
      </section>
      {/* /.section */}
      <Footer />
    </div>
  );
}

export default InventorySettings;
