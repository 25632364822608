import { Link } from "react-router-dom";
import Footer from "../../../Components/ComponentsUI/Footer";
import { defaultStorageHelper } from "@spry/campaign-client";

export default function Landing() {
  const path = window.location.pathname;

  //defaultStorageHelper.remove("SPRYSessionKey");
  return (
    <div className="wrapperUI">
      <div className="mainUi">
        <div className="shellUi">
          <section className="sectionUI">
            <div className="sectionUI__head">
              <img src="/assets/images/ui/svg/logo.svg" width="511" height="104" alt="" />
            </div>
            {/* /.sectionUI__head */}

            <div className="sectionUI__body">
              <h1 className="title-stroke">
                {" "}
                Play Spin-to-Win for the chance to WIN some great prizes!
              </h1>

              <Link to="/register" className="btnUI">
                Enter now
              </Link>
            </div>
            {/* /.section__body */}
          </section>
          {/* /.section */}
        </div>
        {/* /.shell */}
      </div>
      {/* /.main */}

      <Footer openModal={path === "/rules"} />
    </div>
  );
}
