import { FunctionComponent } from "react";

import Modal from "react-modal";

interface PopUpCustomProps {
  setIsModal: (value: boolean) => void;
  children?: string | JSX.Element | JSX.Element[];
  setCancel?: (value: boolean) => void;
}

const PopUpCustom: FunctionComponent<PopUpCustomProps> = ({ setIsModal, children, setCancel }) => {
  return (
    <Modal
      isOpen
      contentLabel="Returning user"
      ariaHideApp={false}
      className="popupUserMessage"
      portalClassName="modal"
      onAfterOpen={() => (document.body.style.overflow = "hidden")}
      onAfterClose={() => (document.body.style.overflow = "unset")}
      style={{
        overlay: {
          zIndex: 1043,
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0,0,0,0.5)",
          //   -webkit-backface-visibility: hidden;
        },
      }}>
      <div
        style={{
          padding: "6rem 5rem 5rem",
          backgroundColor: "#fff",
          color: "black",
          borderRadius: "1rem",
          maxWidth: "500px",
          overflowWrap: "anywhere",
        }}>
        <h3>{children}</h3>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <button
            style={{ marginTop: "4rem" }}
            onClick={() => {
              setIsModal(false);
            }}
            className="btn">
            <span>Ok</span>
          </button>
          {setCancel && (
            <button
              style={{ marginTop: "1rem" }}
              onClick={() => {
                setCancel(false);
              }}
              className="btn btn--transparent form__btn">
              <span>Cancel</span>
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default PopUpCustom;
