import { FunctionComponent } from "react";

interface RulesProps {}

const Rules: FunctionComponent<RulesProps> = () => {
  return (
    <>
      <div className="popup__head">
        <p>
          <strong>CONTEST RULES</strong>
        </p>
        <p>WESTJET SPIN-TO-WIN CONTEST</p>
        <p>(the &ldquo;Contest&rdquo;)</p>
      </div>

      <div className="popup__body" style={{ maxHeight: "35rem" }}>
        <ol>
          <li>
            <strong>Eligibility</strong>
          </li>
        </ol>
        <p>
          The Contest is open only to legal residents of Canada (excluding residents of the province
          of Quebec) who have reached the age of majority in their province of residence on the date
          that they entered the Contest. Void where prohibited by law. Employees, directors,
          officers, representatives, agents of WestJet, an Alberta partnership (the “Sponsor”), Wily
          Global Inc (“WILY”), Experiential Marketing Limited Partnership (“XMC”) (WILY and XMC,
          together, the “Administrators”) and each of their respective parent companies, affiliates,
          franchisees, subsidiaries, distributors, representatives, advertising and promotional
          agencies, agents, sponsors, and any entity that they contract with specific to
          the&nbsp;Contest, as well as the immediate family members, and any persons domiciled with
          any of the above (whether related or not), are not eligible to win a Prize.
        </p>
        <ol start={2}>
          <li>
            <strong>Contest Period</strong>
          </li>
        </ol>
        <p>
          The Contest runs from 3:00pm – Mountain Daylight Savings Time (MDT) on October 27, 2023,
          and ends at 11:59pm MDT on October 28, 2023 (the "Contest Period"), at the 2023 NHL Fan
          Park at the 2023 Tim Hortons Heritage Classic.
        </p>
        <ol start={3}>
          <li>
            <strong>How to Enter </strong>
          </li>
        </ol>
        <p>
          NO PURCHASE IS NECESSARY. To enter the Contest, during the Contest Period: visit a WestJet
          brand ambassador at the Heritage Classic Fan Park and, using the device available visit
          the official Contest Microsite (the “Contest Microsite”) at westjetspintowin.com to
          complete the official online Contest entry form (a “Contest Entry” or “Entry”) by
          providing your e-mail, first name, last name, and follow the instructions to digitally
          spin the Contest wheel (the “Spin-To-Win game”) for your chance to win one of the
          available prizes (each, a “Prize” or “prize”).
        </p>
        <p>Limit one Contest Entry, per e-mail address, per person, during the Contest Period.</p>
        <ol start={4}>
          <li>
            <strong>Prizes</strong>
          </li>
        </ol>
        <p>
          <strong>Grand Prizes</strong>
        </p>
        <p>
          There are two (2) Grand Prizes (the “<strong>Grand Prizes</strong>”) available to be won
          in the Contest, one available during each <u>day</u> of the Contest Period (each, a
          “Contest Day”). Each Grand Prize consists of two (2) WestJet flight vouchers valued at
          approximately $4,240 to be used towards the base fare of a WestJet roundtrip flight for
          two (2) people. The flight vouchers are subject to the terms and conditions outlined in
          Schedule A.
        </p>
        <p>
          <strong>Secondary Prizes</strong>
        </p>
        <p>
          A total of fifty (50) Secondary Prizes (collectively the “
          <strong>Secondary Prizes</strong>”) are available to be won in the Contest as described
          below. During each Contest Day, the following items are available to be won:
        </p>
        <p>
          Ten (10) $100.00 WestJet dollar prizes to be used towards WestJet flight(s). The WestJet
          dollars are subject to the terms and conditions outlined in Schedule B, up to a maximum of
          forty (40) over the Contest Period.
        </p>
        <p>
          Twenty (20) $50.00 WestJet dollar prizes to be used towards WestJet flight(s). The WestJet
          dollars are subject to the terms and conditions outlined in Schedule B, up to a maximum of
          forty (40) over the Contest Period.
        </p>
        <p>
          Twenty (20) $25.00 WestJet dollar prizes to be used towards WestJet flight(s). The WestJet
          dollars are subject to the terms and conditions outlined in Schedule B, up to a maximum of
          forty (40) over the Contest Period.
        </p>
        <p>
          <strong>Third-Tier Prizes</strong>
        </p>
        <p>
          <u>
            A total of three-thousand (3,000) Third-Tier Prizes (collectively the “
            <strong>Third-Tier Prizes”</strong>) are available to be won in the Contest Period as
            described below. During each Contest Day, the following item is available to be won:{" "}
          </u>
        </p>
        <p>
          Up to one thousand (1,000) pairs of “WestJet” sunglasses (approximate retail value: $CAD
          5.00) will be awarded per day to a maximum of one thousand (1,000) over the Contest
          Period.
        </p>
        <p>
          Up to one thousand (1,000) pairs of “WestJet” beach balls (approximate retail value: $CAD
          5.00) will be awarded per day to a maximum of one thousand (1,000) over the Contest
          Period.&nbsp;
        </p>
        <p>
          Up to one thousand (1,000) pairs of “WestJet” fanny packs (approximate retail value: $CAD
          5.00) will be awarded per day to a maximum of one thousand (1,000) over the Contest
          Period.&nbsp;
        </p>
        <p>
          Each Prize must be accepted as awarded and is not transferable, assignable, or convertible
          to cash (except as may be specifically permitted by Sponsor in its sole and absolute
          discretion). No substitutions except at Sponsor’s option. Sponsor reserves the right, in
          its sole and absolute discretion, to substitute a Prize or a component thereof with a
          prize of equal or greater value, including, without limitation, but at Sponsor’s sole and
          absolute discretion, a cash award.&nbsp;
        </p>
        <p>The following conditions apply to the Prizes:</p>
        <ul>
          <li>
            You are responsible for all costs not expressly described as being included as part of a
            Prize, such as any fee associated with the receipt and/or use of the Prize.
          </li>
          <li>
            You must sign the Sponsor’s release form (the “Release”) in order to receive and
            participate in a Grand or Secondary Prize.
          </li>
          <li>
            Any difference between approximate retail value and the actual value of the Prizes as
            received will not be awarded.
          </li>
          <li>Limit of one (1) Prize per person and per email address.</li>
          <li>
            <strong>Grand Prize: Flight Voucher Prize Terms and Conditions:</strong> The following
            additional terms and conditions apply to the Grand Prize: Flight voucher is valid
            towards new reservations booked at www.westjet.com only. Flight voucher cannot be
            redeemed with WestJet via telephone or through a travel agent, and cannot be combined
            with any other offer.
          </li>
        </ul>
        <ol start={5}>
          <li>
            <strong>Odds</strong>
          </li>
        </ol>
        <p>
          During the Contest Period, there are three thousand and fifty-two (3,052) winning times
          (the “Winning Time(s)”). Winning Times will be randomly generated and randomly seeded,
          with an associated Prize, for each Contest Day. The first eligible entrant who plays the
          Spin-To-Win game at or immediately after a Winning Time will receive a Winning Message and
          will be eligible to win the Prize associated with that Winning Time. The odds of winning
          the Prize depend on the time at which the Spin-To-Win game is played. The number of
          available Prizes diminish, and odds of winning change, each Contest Day, as Prizes are
          won.
        </p>
        <ol start={6}>
          <li>
            <strong>Winner Confirmation, Release and Skill Testing Question</strong>
          </li>
        </ol>
        <p>
          Once you have entered the Contest by playing the Spin-To-Win game, you will automatically
          be notified on-screen as to whether or not you are the eligible winner of a Prize. If you
          are eligible to win a Prize, you will automatically be notified on-screen with a message
          (the “Winning Message”) indicating that you are eligible to win a Prize, including a
          unique alphanumeric Prize code (the “Winning Code”). If you receive a Winning Message, to
          be confirmed as a Prize winner, you must present your Winning Code to the Sponsor’s brand
          ambassador on the same Contest Day on which you received the Winning Message, at which
          point you: (i) will be required to correctly answer, unaided, a mathematical skill-testing
          question; and (ii) may be required to complete a declaration and release form (the
          “Release Form”) confirming, among other things, compliance with these Official Rules and
          acceptance of the Prize, as awarded. Upon confirmation that you have successfully
          completed these requirement(s), you will be declared a winner and receive the Prize won.
        </p>
        <ol start={7}>
          <li>
            <strong>Forfeit of Prize</strong>
          </li>
        </ol>
        <p>
          If a potential Prize winner fails to: (a) correctly answer the skill-testing question; (b)
          complete the Release Form (if applicable); (c) cannot accept (or is unwilling to accept)
          the applicable Prize for any reason; or (d) is determined to be in violation of these
          Official Rules (as determined by the Sponsor in its sole and absolute discretion); then
          he/she/they will be disqualified and will forfeit all rights to the applicable Prize. Any
          forfeited or unclaimed Prizes will NOT be awarded, except in the sole and absolute
          discretion of the Sponsor.
        </p>
        <ol start={8}>
          <li>
            <strong>Use of Personal Information</strong>
          </li>
        </ol>
        <p>
          By entering the Contest and voluntarily providing your personal information as described
          in these Contest Rules, you agree to the collection, use and disclosure by the Sponsor,
          the Administrators, and their respective employees and/or authorized agents, of your
          personal information, for the purpose of administering the Contest, including—but not
          limited to—contacting you with respect to the Contest and to advise whether or not you are
          a potential winning Entrant. Personally-identifiable information will only be used to
          administer the Contest and for no other purpose, except as specifically outlined in these
          Contest Rules or as you may have otherwise consented to. Personally-identifiable
          information will not be sold, shared or disclosed by the Sponsor or the Administrators to
          any third party, other than to a third party engaged by the Sponsor for the purpose of
          administering the Contest, or if required by law, with the exception that aggregated
          information may be used by or shared amongst the Sponsor, the Administrators and their
          subsidiaries and affiliates, or shared by the Sponsor with third parties in accordance
          with the Sponsor’s Privacy Commitment available at
          https://www.westjet.com/en-ca/legal/privacy-policy.
        </p>
        <ol start={9}>
          <li>
            <strong>Publicity Consent</strong>
          </li>
        </ol>
        <p>
          By entering the Contest, you agree that if you are determined to be a winner, the Sponsor
          may use your name, comments, voice, likeness, municipality of residence, your contest
          Entry and picture in any advertising, promotion or publicity carried out now or in the
          future, in any media without compensation or notice, and you grant to the Administrator
          and the Sponsor any and all rights to such use. You agree to cooperate with the Sponsor in
          arranging for photographs or other forms of publicity and to be available for photographs
          or other forms of publicity on reasonable notice.
        </p>
        <ol start={10}>
          <li>
            {" "}
            <strong>Limitation of Liability / Release</strong>
          </li>
        </ol>
        <p>
          By participating in the Contest, and as a condition of acceptance of the Prize, you, your
          heirs, executors, administrators, successors and assigns, release and forever discharge
          and hold harmless the Sponsor and the Administrators, and each of their direct and
          indirect parent companies, affiliates, subsidiaries, successors, assigns, agents,
          advisors, franchisees, shareholders, partners, representatives, their advertising,
          promotion and fulfillment agencies and each of their respective employees, officers,
          directors, agents and representatives (collectively, the “Released Parties”), from and
          against any and all losses, damages (including, without limitation, direct, indirect,
          incidental, consequential or punitive damages), rights, claims, actions, causes of action,
          personal injury, property damage or death, including without limitation all costs and
          liabilities of any kind including legal fees on a substantial indemnity scale, hereinafter
          called “Claims” and including without limitation any claims arising from any act of
          negligence of the Released Parties, that you now have, or may hereafter have against the
          Released Parties directly or indirectly resulting or arising from: (i) participation in
          the Contest, your Entry and/or the awarding, acceptance, possession, use, or misuse or
          enjoyment of any Prize, and where applicable, travelling to, preparing for, or
          participating in, any Contest-related or Prize-related event or activity; or (ii) the
          publicity rights granted to the Sponsor. You specifically acknowledge that you understand
          that there may be serious risks of bodily injury, death, or property damage associated
          with the acceptance, possession, use and/or misuse of any Prize and attendance at any
          event or participation in certain Prize-related activities, and you voluntarily assume
          these risks. You also agree to indemnify, defend, and hold harmless the Released Parties
          from any and all Claims in respect of the foregoing.
        </p>
        <ol start={11}>
          <li>
            <strong>Compliance with Rules</strong>
          </li>
        </ol>
        <p>
          By entering the Contest, you agree to abide by the entire Contest Rules and the terms and
          conditions under which the prizes are awarded. Any decision made by the Sponsor in respect
          of this Contest shall be final.
        </p>
        <ol start={12}>
          <li>
            <strong>Technical Issues</strong>
          </li>
        </ol>
        <p>
          The Sponsor and Administrators are not responsible for any computer, online, telephone,
          hardware, software or technical limitations or malfunctions that may occur (including but
          not limited to malfunctions that may affect the transmission or non-transmission of an
          Entry, or failure to receive an Entry), nor for any incorrect or inaccurate information,
          whether caused by website users or by any of the equipment or programming associated with
          or utilized in the Contest or by any technical or human error which may occur in the
          processing of entries in the Contest, nor for any error, omission, interruption, deletion,
          defect, delay in operation or transmission or receipt of Entry, communications line
          failure, theft or destruction or unauthorized access to, or alteration of entries; and,
          are not responsible for any problems, failures or technical malfunction of any telephone
          or network lines, computer online systems, servers, providers, computer equipment,
          software, email, players, or browsers, on account of technical problems or traffic
          congestion on the Internet, at any website or on account of any combination of the
          foregoing; and are not responsible for any injury or damage to entrants or to any computer
          related injury or damage resulting from participating in downloading materials in this
          Contest.
        </p>
        <p>
          In the event that, due to a printing, manufacturing, mechanical or other error of any kind
          or nature whatsoever, more Prizes are claimed than are intended to be awarded according to
          these Contest Rules, Prizes will stop being awarded when the Sponsor or Administrators
          become aware of an error respecting the number of Prizes and, in such event, the Sponsor
          reserves the right, in its sole and absolute discretion, to conduct a random draw from
          amongst all eligible and approved Prize claimants whose claims have not yet been redeemed
          to award the remaining number of advertised Prizes. In no event will the Sponsor or
          Administrators be liable for more than the stated number of Prizes contained in these
          Contest Rules.
        </p>
        <ol start={13}>
          <li>
            {" "}
            <strong>Rights to Terminate/Modify/Suspend Contest</strong>
          </li>
        </ol>
        <p>
          The Sponsor retains the right, in its absolute discretion, to suspend, modify or terminate
          any aspect of the Contest (including, but not limited to these Contest Rules) at any time.
          The Sponsor may make substitutions of equivalent kind or value in the event of the
          unavailability of a prize (or component thereof) for any reason whatsoever. The Sponsor
          may terminate or withdraw this Contest at any time in which event it shall post a notice
          on the Sponsor’s website. If the Contest is terminated on any day prior to the end of the
          Contest Period (the “Termination Date”), the Sponsor may, at its sole discretion,
          determine the winners from all eligible entries received as of 12:00 p.m. MDT on the
          Termination Date (which winners shall be randomly selected in accordance with these
          Contest Rules).
        </p>
        <ol start={14}>
          <li>
            <strong>General Disqualification</strong>
          </li>
        </ol>
        <p>
          Any attempt made by an entrant or other individual or entity, to deliberately damage,
          manipulate or undermine the legitimate operation of the Contest, including but not limited
          to any fraudulent claims, is a violation of the law. The Administrators and Sponsor
          reserve the right to seek remedies and damages from any such individual or entity which
          makes any such attempt. Any entrant who, in the opinion of the Sponsor or Administrators,
          is determined to be engaging in any of the foregoing activities will be immediately
          disqualified from the Contest.
        </p>
        <ol start={15}>
          <li>
            <strong>Jurisdiction</strong>
          </li>
        </ol>
        <p>
          The Contest is governed by the laws of Alberta and the laws of Canada applicable therein,
          and is void where prohibited by law. Participation constitutes your full and unconditional
          agreement to these Contest Rules and the decisions of the Sponsor, which are final and
          binding in all matters related to the Contest. By entering the Contest, you agree that the
          courts of Alberta, Canada shall have exclusive jurisdiction to entertain any action or
          other legal proceedings based on any provision of this Contest’s Rules or the running of
          this Contest.
        </p>
      </div>
    </>
  );
};

export default Rules;
