import { Outlet } from "react-router-dom";
import { useRequiredCampaignState } from "../../helpers/campaignStates";

export default function Open() {
  useRequiredCampaignState("open");

  return (
    <div className="body">
      <Outlet />
    </div>
  );
}
