import { SPRYCampaignClient, SessionClient } from "@spry/campaign-client";
import axios from "axios";

export interface IEmailTemplate {
  campaignKey: string;
  language: string;
  emailTemplateName: string;
  emailTemplateKey?: string | null;
  subject?: string;
  htmlBody?: string | null;
  textBody?: string | null;
}

export interface IUser {
  firstName?: string;
  lastName?: string;
  email?: string;
  code?: string;
  username?: string;
  password?: string;
  token?: string;
}

const endPoint = process.env.REACT_APP_API_ENDPOINT || "http://localhost:31337/admin";

const publicEndpoint = process.env.REACT_APP_API_PUBLIC_ENDPOINT || "http://localhost:31337/api";

const campaignClient = new SPRYCampaignClient(axios, endPoint!);

const publicCampaignClient = new SPRYCampaignClient(axios, publicEndpoint!);
function setSessionClient(_sessionClient: SessionClient) {
  return (sessionClient = _sessionClient);
}

let sessionClient: SessionClient = publicCampaignClient.recoverSession();

function getSessionClient() {
  return sessionClient;
}

const setupApiClient = (token: string) => {
  campaignClient.setAuthorization(token);
};


export {
  campaignClient,
  publicCampaignClient,
  setupApiClient,
  endPoint,
  getSessionClient,
  setSessionClient,
};
