export const COGNITO_CONFIG = {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    //identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
    mandatorySignId: true,
    region: process.env.REACT_APP_COGNITO_REGION || 'ca-central-1',
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL || 'ca-central-1_IRRFCwKGx',
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT || '3ro4i67pt1j020jblshopqfp9r',
    // OPTIONAL - Hosted UI configuration
    oauth: {
        //domain: 'your_cognito_domain',
        //scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        //redirectSignIn: 'http://localhost:3000/',
        //redirectSignOut: 'http://localhost:3000/',
        //responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
}

export const redirectWwwToApex = true

export const redirectApexToWww = false

export const DEFAULT_THEME = {
    baseLayoutLogo: '/assets/images/svg/sprite.svg#logo-main'
}

export const DEFAULT_COPY = {
    
}



