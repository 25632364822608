import { useEffect, useState } from "react";
import { campaignClient } from "../../../api";
import { emailRegex } from "../../../helpers/utils";
import Header from "../../../Components/Header";
import Footer from "../../../Components/Footer";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import PopUpCustom from "../../../Components/PopUpCustom";
import "./css.css";
import { defaultStorageHelper } from "@spry/campaign-client";
import Loading from "../../../Components/Loading";

type RegisterFormData = {
  email: string;
  prizeCode: string;
};

function PrizeSearch() {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isModal, setIsModal] = useState(false);
  const [winnerData, setWinnerData] = useState({
    lastName: "",
    status: "",
    prizeCode: "",
    firstName: "",
    prizeName: "",
    winKey: "",
    sk: "",
  });

  useEffect(() => {
    defaultStorageHelper.remove("details");
  }, []);

  const clearWinnerDetails = () => {
    setWinnerData({
      lastName: "",
      status: "",
      prizeCode: "",
      firstName: "",
      prizeName: "",
      sk: "",
      winKey: "",
    });
    reset();
  };

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm<RegisterFormData>();

  async function handlePrizeSearch(data: RegisterFormData) {
    if (submitting) {
      return;
    }
    if (!data.email && !data.prizeCode) {
      setError("email", {
        type: "manual",
        message: "Please enter a prize code or email.",
      });
      return;
    }
    setSubmitting(true);
    setIsModal(false);
    try {
      const {
        lastName,
        firstName,
        prizeName,
        prizeCode,
        status,
        error,
        message,
        code,
        sessionKey,
        winKey,
      }: {
        sessionKey: string;
        error: string;
        message: string;
        code: string;
        firstName: string;
        lastName: string;
        prizeName: string;
        prizeCode: string;
        status: string;
        winKey: string;
      } = await campaignClient.call("checkClaim", {
        email: data.email,
        prizeCode: data.prizeCode,
      });
      if (error === "INVALID_PRIZE_CODE") {
        setIsModal(true);
        setErrorMessage(message);
      } else if (lastName && firstName && prizeName && status) {
        setWinnerData({
          lastName,
          firstName,
          prizeCode,
          status,
          prizeName,
          sk: sessionKey,
          winKey: winKey,
        });
      } else if (code === "email_in_use") {
        setIsModal(true);
        setErrorMessage(message);
      } else if (error && message) {
        setErrorMessage(message);
        setIsModal(true);
      } else if (error) {
        setErrorMessage(message);
        setIsModal(true);
      }
    } catch {
      setIsModal(true);
      setErrorMessage("Something went wrong, please try again later.");
    }
    setSubmitting(false);
  }

  return (
    <div className="wrapper">
      {isModal && errorMessage && <PopUpCustom setIsModal={setIsModal}>{errorMessage}</PopUpCustom>}

      <Header />
      {submitting && <Loading />}
      <section className="section">
        <div className="shell">
          {!(
            winnerData.sk ||
            winnerData.firstName ||
            winnerData.lastName ||
            winnerData.prizeCode ||
            winnerData.prizeName ||
            winnerData.status
          ) ? (
            <div className="section__inner">
              <div className="section__head">
                <h2>Search for a Prize Code or Email</h2>

                <p>
                  Enter the email or prize code that a winner presents and click submit. Once you
                  find their prize win record, you can award the prize to them.
                </p>
              </div>
              {/* /.section__ head */}

              <div className="section__body">
                <div className="form">
                  <form onSubmit={handleSubmit(handlePrizeSearch)}>
                    <div className="form__body">
                      <div className="form__row">
                        <label htmlFor="prize-code" className="form__label">
                          Prize code
                        </label>

                        <div className="form__controls">
                          <input
                            placeholder="Prize code"
                            className="field"
                            {...register("prizeCode", {
                              min: {
                                value: 6,
                                message: "Please enter a valid prize code.",
                              },
                              max: {
                                value: 10,
                                message: "Please enter a valid prize code.",
                              },
                            })}
                            onChange={(e) => {
                              clearErrors("email");
                            }}
                          />
                        </div>
                        {errors.prizeCode && (
                          <p className="error-message">
                            <img
                              src="/assets/images/svg/exclamation-mark.svg"
                              title="Exclamation Mark"
                            />{" "}
                            {errors.prizeCode.message}
                          </p>
                        )}
                      </div>

                      <div className="form__row">
                        <label htmlFor="email" className="form__label">
                          Email
                        </label>

                        <div className="form__controls">
                          <input
                            placeholder="Email"
                            className="field"
                            {...register("email", {
                              pattern: {
                                value: emailRegex,
                                message: "Please enter a valid email.",
                              },
                            })}
                            onChange={(e) => {
                              clearErrors("email");
                            }}
                          />
                        </div>
                        {errors.email && (
                          <p className="error-message">
                            <img
                              src="/assets/images/svg/exclamation-mark.svg"
                              title="Exclamation Mark"
                            />{" "}
                            {errors.email.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form__actions">
                      <button type="submit" className="btn form__btn">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
                {/* /.form */}
              </div>
              {/* /.section__body */}
            </div>
          ) : (
            <div className="section__inner">
              <div className="section__head">
                <h2>Winner Details</h2>
                <p>Please review the info below before continuing to the skill-testing question</p>
              </div>
              {/* /.section__ head */}
              <div className="section__body">
                <div className="table">
                  <table className="tableU">
                    <tbody>
                      <tr className="tableTR">
                        <td>
                          {" "}
                          <b> First Name</b>
                        </td>

                        <td>{winnerData.firstName}</td>
                      </tr>

                      <tr className="tableTR">
                        <td>
                          {" "}
                          <b>Last Name</b>
                        </td>

                        <td>{winnerData.lastName}</td>
                      </tr>
                      <tr className="tableTR">
                        <td>
                          <b> Prize</b>
                        </td>

                        <td>{winnerData.prizeName}</td>
                      </tr>

                      <tr className="tableTR">
                        <td>
                          <b> Prize Code</b>
                        </td>

                        <td>{winnerData.prizeCode}</td>
                      </tr>

                      <tr className="tableTR">
                        <td>
                          <b>Status</b>
                        </td>

                        <td>{winnerData.status}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="form form--inventory" style={{ marginTop: "20px" }}>
                  {/* /.form__body */}
                  <div className="form__actions">
                    <button
                      disabled={winnerData.status === "Claimed" && true}
                      type="submit"
                      className={
                        winnerData.status === "Claimed" ? " form__btn btn_disable" : "btn form__btn"
                      }
                      onClick={() => {
                        const state = {
                          prizeName: winnerData.prizeName,
                          winKey: winnerData.winKey,
                        };
                        navigate("/portal/stq/" + winnerData.sk, { state });
                      }}>
                      Continue
                    </button>
                    <button
                      type="button"
                      className="btn btn--transparent form__btn"
                      onClick={clearWinnerDetails}>
                      Cancel
                    </button>
                  </div>
                </div>
                {/* /.form */}
              </div>
              {/* /.section__body */}
            </div>
          )}
          {/* /.section__inner */}
        </div>
        {/* /.shell */}
      </section>
      {/* /.section */}

      <Footer />
    </div>
  );
}

export default PrizeSearch;
