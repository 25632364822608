import { FunctionComponent } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";

interface ConfirmationProps {
  title: string;
  body: string;
  titleAction: string;
  actionPath: string;
  style?: {};
}

const Confirmation = ({ style, actionPath, title, body, titleAction }: ConfirmationProps) => {
  return (
    <div className="wrapper">
      <Header />
      <section className="section">
        <div className="shell">
          <div className="section__inner">
            <div className="section__head">
              <figure className="section__icon">
                <img src="/assets/images/svg/ico-check.svg" alt="" />
              </figure>
              {/* /.section__icon */}
              <h2>{title}</h2>
              <p style={style}>{body}</p>
            </div>
            {/* /.section__ head */}
            <div className="section__actions">
              <a href={actionPath} className="btn">
                {titleAction}
              </a>
            </div>
            {/* /.section__actions section__actions-/-block */}
          </div>
          {/* /.section__inner */}
        </div>
        {/* /.shell */}
      </section>
      {/* /.section */}
      <Footer />
    </div>
  );
};

export default Confirmation;
