export const renderProvincesDropdown = () => {
  const provinces: any = [
    "Alberta",
    "British Columbia",
    "Manitoba",
    "New Brunswick",
    "Newfoundland and Labrador",
    "Northwest Territories",
    "Nova Scotia",
    "Nunavut",
    "Ontario",
    "Prince Edward Island",
    "Quebec",
    "Saskatchewan",
    "Yukon",
  ]
  return provinces.map(function (province: any) {
    return (
      <option value={province} key={province}>
        {province}
      </option>
    )
  })
}
