import { Route, Navigate } from "react-router-dom";
import { Closed, ComingSoon, InventorySettings, Login, PrizeSearch } from "../Pages/PrizePortal";
import { Layout } from "../Components";

import STQ from "../Pages/PrizePortal/STQ";

import InventoryPrize from "../Pages/PrizePortal/InventoryPrize";
import Confirmation from "../Pages/PrizePortal/STQConfirmation"
import InventoryConfirmation from "../Pages/PrizePortal/InventoryConfirmation";
import Open from "../Pages/Open";
import Landing from "../Pages/Open/Landing";
import Registration from "../Pages/Open/Registration";
import PrizeReveal from "../Pages/Open/PrizeReveal";
import SpinWheel from "../Pages/Open/SpinWheel";

export default (
  <>
    <Route path="/portal" element={<Layout />}>
      <Route index element={<Navigate to="/portal/prize-search" />} />
      <Route path="prize-search" element={<PrizeSearch />} />
      <Route path="confirmation" element={<Confirmation />} />
      <Route path="inventory-conf" element={<InventoryConfirmation />} />
      <Route path="inventory-prize" element={<InventoryPrize />} />
      <Route path="inventory" element={<InventorySettings />} />
      <Route path="login" element={<Login />} />

      <Route path="stq/:sk" element={<STQ />} />
    </Route>
    <Route path="/" element={<Open />}>
      <Route path="/" element={<Landing />} />
      <Route path="rules" element={<Landing />} />
      <Route path="register" element={<Registration />} />
      <Route path="prize-reveal" element={<PrizeReveal />} />
      <Route path="spin-wheel" element={<SpinWheel />} />
    </Route>
    <Route path="/coming" element={<ComingSoon />} />
    <Route path="/closed" element={<Closed />} />
  </>
);
