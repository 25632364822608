import { useRequiredCampaignState } from "../../helpers/campaignStates";
import Footer from "../../Components/ComponentsUI/Footer";

export default function Closed() {
  //useRequiredCampaignState("closed");
  return (
    <div className="wrapperUI">
      <div className="mainUi">
        <div className="shellUi">
          <section className="sectionUI sectionUI--text">
            <div className="sectionUI__head">
              <a href="#" className="logo">
                <img src="/assets/images/svg/logo.svg" width="511" height="104" alt="" />
              </a>
            </div>
            {/* /.section__head */}

            <div className="sectionUI__body" style={{ margin: "11rem", textAlign: "center" }}>
              <h1 className="text-large">This site is now closed</h1>
            </div>
            {/* /.section__body */}
          </section>
          {/* /.section */}
        </div>
        {/* /.shell */}
      </div>
      {/* /.main */}

      <Footer />
    </div>
  );
}
