import { FunctionComponent } from "react";
import Confirmation from "../../../Components/Confirmation";
import { useLocation } from "react-router-dom";

const InventoryConfirmation = ({}) => {
  const { state } = useLocation();
  const date = state.date;

  const title = "Prize inventory confirmed";
  const body = "The prize inventory has been set for date: " + "\n" + date;

  return (
    <Confirmation
      style={{ whiteSpace: "pre-wrap" }}
      title={title}
      body={body}
      actionPath="/portal/inventory-prize"
      titleAction="Back to prize inventory"
    />
  );
};

export default InventoryConfirmation;
