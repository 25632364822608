import { FunctionComponent } from "react";

import Modal from "react-modal";

interface PopUpModalProps {
  setIsModal: (value: boolean) => void;
  children?: string | JSX.Element | JSX.Element[];
}

const PopUpModal: FunctionComponent<PopUpModalProps> = ({ setIsModal, children }) => {
  return (
    <Modal
      isOpen
      contentLabel="Returning user"
      ariaHideApp={false}
      className="popupUserMessage"
      portalClassName="modal"
      onAfterOpen={() => (document.body.style.overflow = "hidden")}
      onAfterClose={() => (document.body.style.overflow = "unset")}
      style={{
        overlay: {
          zIndex: 1043,
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0,0,0,0.5)",
          //   -webkit-backface-visibility: hidden;
        },
      }}>
      <div
        style={{
          padding: "6rem 5rem 5rem",
          backgroundColor: "#fff",
          color: "black",
          borderRadius: "1rem",
          maxWidth: "500px",
          maxHeight: "75dvh",
          overflowWrap: "anywhere",
        }}>
        <div className="popup is-open">
          <div className="popup__card">
            <button
              onClick={() => {
                setIsModal(false);
                // window.location.pathname === "/rules" &&
                //    window.location.assign("/");
              }}
              className="popup__close">
              <img src="/assets/images/svg/ico-x.svg" alt="" />
            </button>

            {children}

            {/* /.popup__body */}
          </div>
          {/* /.popup__card */}
        </div>

        {/* <button
          onClick={() => {
            setIsModal(false);
          }}
          className="btn grey"
        >
          <span>Ok</span>
        </button> */}
      </div>
    </Modal>
  );
};

export default PopUpModal;
