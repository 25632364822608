import "./index.css";
import { Authenticator, translations } from "@aws-amplify/ui-react";
import { Hub } from "aws-amplify";
import { useEffect, useState } from "react";
import { publicCampaignClient, setupApiClient } from "../../../api";
import { I18n } from "aws-amplify";
import { useBodyClass } from "../../../helpers/hooks";
import Footer from "../../../Components/Footer";
import { useStores } from "../../../stores";
I18n.putVocabularies(translations);

function Login() {
  useBodyClass("body--light");
  const [userName, setUserName] = useState(null);
  const [userStatus, setUserStatus] = useState("");

  const store = useStores()

  useEffect(() => {
    Hub.listen("auth", (data: any) => {  
      if (data.payload.message) {
        setupApiClient(data.payload.data.signInUserSession.accessToken.jwtToken);
       
        setUserName(data.payload.message.split(" ")[0]);
        setUserStatus(data.payload.data.message);
        store.authStore.setup()
      }
    });
  }, [Hub]);

  useEffect(() => {
    customizeValidationErrorMessage();
    customizeForgotTempPasswordErrorMessage();
  }, [userStatus, userName]);

  const customizeValidationErrorMessage = () => {
    const originalMessageForValidationError =
      "2 validation errors detected: Value at 'userAlias' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+; Value at 'userName' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+";
    const translatedMessageForValidationError =
      "Please provide a valid email address as your username.";
    if (
      userStatus ==
      "2 validation errors detected: Value at 'userAlias' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+; Value at 'userName' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+"
    ) {
      I18n.putVocabularies({
        en: {
          [originalMessageForValidationError]: [translatedMessageForValidationError],
        },
      });
    }
  };

  const customizeForgotTempPasswordErrorMessage = async () => {
    const originalMessageForForgotTempPassword =
      "User password cannot be reset in the current state.";
    const translatedMessageForForgotTempPassword =
      "Temporary password has been resent to the user.";
    if (userName !== null && userStatus === "User password cannot be reset in the current state.") {
      I18n.putVocabularies({
        en: {
          [originalMessageForForgotTempPassword]: [translatedMessageForForgotTempPassword],
        },
      });
      try {
        await publicCampaignClient.call("signUp", {
          userName: userName,
        });
      } catch (error) {
        console.log(error);
      }
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };

  return (
    <div className="wrapper">
      <section className="section section--small-height">
        <div className="shell">
          <div className="section__inner">
            <div className="section__head">
              <a className="section__logo logo logo--large">
                <img src="/assets/images/svg/logo-admin.svg" width="315" height="64" alt="" />
              </a>
              {/* /.section__logo */}

              <h2>Login</h2>

              <p>Please use the login information provided by the promotion administrator. </p>
              <Authenticator hideSignUp={true} />
            </div>
            {/* /.section__ head */}

            <div className="section__body">
              <div className="form"></div>
              {/* /.form */}
            </div>
            {/* /.section__body */}
          </div>
          {/* /.section__inner */}
        </div>
        {/* /.shell */}
      </section>
      {/* /.section */}

      <Footer />
    </div>
  );
}

export default Login;
