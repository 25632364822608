import { useEffect, useState } from "react";
import ZingTouch from "zingtouch";
import { useNavigate } from "react-router-dom";
import disableBrowserHistory from "../../../Components/ComponentsUI/DisableBrowserBack";

import { getSessionClient } from "../../../api";
import { defaultStorageHelper } from "@spry/campaign-client";
import Loading from "../../../Components/ComponentsUI/Loading";

const prz_array = [
  "e967d41f61526eb6d85763dfbbab0f2e", //"One (1) WestJet Roundtrip Flight"
  "4edd0482742a591352ff697b8915e07d", //One (1) WestJet Fanny Pack
  "bc41ed5efdf19a109f9dc996b1cf52ba", //$25 WestJet Dollars
  "a4599f979d5fd43be0b96aa75348004e", //$100 WestJet Dollars
  "e967d41f61526eb6d85763dfbbab0f2e", //"One (1) WestJet Roundtrip Flight"
  "4edd0482742a591352ff697b8915e07d", //One (1) WestJet Fanny Pack
  "bc41ed5efdf19a109f9dc996b1cf52ba", //$25 WestJet Dollars
  "8410882f99f8ed501b2d141057039c9b", //WestJetDollars50
];

declare const window: Window &
  typeof globalThis & {
    vex: any;
    init_spin: any;
    createjs: any;
  };

export default function SpinWheel() {
  disableBrowserHistory();
  const navigate = useNavigate();

  const [anLib, setAnLib] = useState<any>(null);
  const [prizeLoading, setPrizeLoading] = useState(false);

  let play = false;

  useEffect(() => {
    const initGame = () => {
      window.init_spin((stage: any) => {
        setAnLib(stage.children[0]);
      });
    };
    initGame();
  }, []);

  useEffect(() => {
    const canvas = document.getElementById("canvas");

    const _zr = ZingTouch.Region(canvas);

    if (anLib) {
      const _zr_touch = new ZingTouch.Tap({ maxDelay: 1000 });
      _zr.bind(canvas, _zr_touch, (e: any) => {
        if (!play) {
          play = true;
          handleSpin();
        }
      });
      const _zr_pan = new ZingTouch.Rotate();
      _zr.bind(canvas, _zr_pan, (e: any) => {
        const temp = e.detail;
        if (!play && temp.distanceFromLast != 0) {
          play = true;
          handleSpin();
        }
      });
    }

    return () => {
      if (anLib) {
        _zr.unbind(canvas);
      }
    };
  }, [anLib]);

  const handleSpin = async () => {
    setPrizeLoading(true);
    const dir = Math.floor(Math.random() * 2) === 0 ? -1 : 1;

    const {
      code,
      error,
      message,
      prizeKey,
      description,
      prizeCode,
    }: {
      error: string;
      code: string;
      message: string;
      prizeKey: string;
      description: string;
      prizeCode: string;
    } = await getSessionClient().call("instantWin", {});
    setPrizeLoading(false);

    if (code && message) {
      window.vex.dialog.alert({
        unsafeMessage: message,
        callback: () => {
          return navigate("/");
        },
      });
      return;
    } else if (error && message) {
      window.vex.dialog.alert({
        unsafeMessage: `Sorry we couldn't find your information, Please try again.`,
        callback: () => {
          return navigate("/");
        },
      });
      return;
    } else {
      defaultStorageHelper.set("prize", description + "-" + prizeCode);
      playGame(dir, { prizeKey });
    }
  };

  const playGame = (direction: any, prz: any) => {
    const prz_num = prz_array.length;

    const prz_angle = Math.floor(360 / prz_num);

    const offset =
      (Math.floor(Math.random() * 2) === 0 ? -1 : 1) *
      Math.floor(Math.random() * (prz_angle * 0.45));

    const prz_pwd = Math.floor(Math.random() * 2 + 1);

    const prz_opt_id = prz ? prz.prizeKey : "";

    const prz_idx = prz_array.indexOf(prz_opt_id);

    const seg = direction < 0 ? prz_idx : prz_num - prz_idx;

    const angle = 360 * prz_pwd + prz_angle * seg + offset;

    window.createjs.Tween.get(anLib.spin_wheel, {
      loop: false,
    })
      .to(
        {
          rotation: angle * direction,
        },
        5000,
        window.createjs.Ease.circOut
      )
      .call(function () {
        setTimeout(() => {
          let route = "/prize-reveal";
          navigate(route);
        }, 2000);
      });
    // }
  };

  return (
    <div className="wrapperUI">
      {(prizeLoading || !anLib) && <Loading />}
      <div className="mainUi">
        <div className="shell">
          <section className="sectionUI">
            <div className="sectionUI__head">
              <a href="/" className="logo">
                <img src="/assets/images/svg/logo.svg" width="511" height="104" alt="" />
              </a>
            </div>
            {/* /.sectionUI__head */}

            <div className="sectionUI__body">
              <figure className="sectionUI__image">
                <div id="animation_container">
                  <canvas id="canvas" width="980" height="980" />
                  <div id="dom_overlay_container"></div>
                </div>
                <div id="_preload_div_">
                  <span />
                  {/* <img src="image/_preloader.gif" /> */}
                </div>
              </figure>
              {/* /.section__image */}

              <h2 className="h1">Time to spin!</h2>
              {/* /.h1 */}

              <h3 className="h2">Tap the wheel for your chance to WIN!</h3>
              {/* /.h2 */}
            </div>
            {/* /.section__body */}
          </section>
          {/* /.section */}
        </div>
        {/* /.shell */}
      </div>
      {/* /.main */}
    </div>
  );
}
