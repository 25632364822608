import { Routes } from "react-router-dom";
import CustomRoutes from "./Config/Routes";
import { useStores, StoreContext } from "./stores";
import ScrollToTop from "./Components/ScrollToTop";

declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

function App() { 

  const stores = useStores();

return <StoreContext.Provider value={stores}>
    <ScrollToTop />
   <Routes>{CustomRoutes}</Routes>
</StoreContext.Provider>
}

export default App;
