import { useEffect, useRef, useState } from "react";
import { Outlet, useNavigate, useMatch, useLocation, Navigate } from "react-router-dom";

import { useStores } from "../../stores";
import { setupApiClient } from "../../api";
import { Auth } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Login from "../../Pages/PrizePortal/Login";

function Layout() {

  const intervalRef = useRef<NodeJS.Timer | undefined>(undefined);

  const match = useMatch({ path: "portal", end: true });

  const location = useLocation()

  const { authStore } = useStores();

  const [toggle, setToggle] = useState(false);

  const [mobileToggle, setMobileToggle] = useState(false);

  const navigate = useNavigate();

  const { route, user, authStatus } = useAuthenticator((context) => [context.route, context.user, context.authStatus]);


  useEffect(() => {

    if (!intervalRef.current && route === "authenticated" && user){
      authStore.setup()
      const interval = setInterval(async () => {
        try {
          const cognitoUser = await Auth.currentAuthenticatedUser();
          const { refreshToken } = cognitoUser.getSignInUserSession();
          cognitoUser.refreshSession(refreshToken, (err: any, session: any) => {
            setupApiClient(session.idToken.jwtToken);
          });
        } catch (e) {
          throw e;
        }
      }, 1200000);
  
      intervalRef.current = interval;
    }

   
    // const handleClick = (event: any) => {
    //   setToggle(false);
    // };

    // document.addEventListener("click", handleClick, true);

    // return () => {
    //   //document.removeEventListener("click", handleClick, true);
    //   if (intervalRef){
    //     clearInterval(intervalRef.current)
    //   }
      
    // };
  }, []);

  if (authStatus === 'configuring'){
    return <></>
  }
  
  if (authStatus !== "authenticated") {   
    return <Login />
  }

  // const logOut = async () => {
  //   try {
  //     await authStore.logout();

  //     navigate("/portal/", { replace: true });
  //   } catch (error) {
  //     console.error("error signing out: ", error);
  //   }
  // };

  return (
    route === "authenticated" && user.attributes?.profile === "BA" ? <Outlet /> : <Login />
  )
}

export default Layout;
