import { useState, useEffect } from "react";
import { campaignClient } from "../../../api";
import dayjs from "dayjs";
import Header from "../../../Components/Header";
import Footer from "../../../Components/Footer";
import PopUpCustom from "../../../Components/PopUpCustom";
import { useNavigate } from "react-router-dom";
import Loading from "../../../Components/Loading";

type InventoryData = {
  flight: number;
  dollars25: number;
  dollars50: number;
  dollars100: number;
};

function InventoryPrize() {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [isModal, setIsModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [prizeInventoryDates, setPrizeInventoryDates] = useState<string[]>([]);
  const [inventoryData, setInventoryData] = useState<InventoryData>({
    flight: 0,
    dollars25: 0,
    dollars50: 0,
    dollars100: 0,
  });
  const [totalQty, setTotalQty] = useState<InventoryData>();

  const state = { selectedDate, inventoryData };

  useEffect(() => {
    const fetchCampaignState = async () => {
      const state = await campaignClient.call<{
        state: string;
        metadata: { start: string; end: string };
      }>("getCampaignState", {});
      if (state.metadata.start && state.metadata.end) {
        const prizeDates: string[] = [];
        let start_date = dayjs(state.metadata.start);
        while (start_date.isBefore(state.metadata.end, "day")) {
          prizeDates.push(start_date.format("YYYY-MM-DD"));
          start_date = start_date.add(1, "day");
        }
        prizeDates.push(start_date.format("YYYY-MM-DD"));
        setPrizeInventoryDates(prizeDates);
        setSelectedDate(prizeDates[0]);
      }
    };
    fetchCampaignState();
  }, []);

  const fetchData = async () => {
    if (!selectedDate) {
      return;
    }
    // setSubmitting(true);
    try {
      const {
        prizeQuantity: { flight, dollars25, dollars50, dollars100 },
        totalQuantity,
        error,
        code,
        message,
      }: {
        prizeQuantity: InventoryData;
        totalQuantity: InventoryData;

        result: boolean;
        error: string;
        code: string;
        message: string;
      } = await campaignClient.call("getInventory", {
        selectedDate,
      });
      if (flight > -1 || dollars25 > -1 || dollars50 > -1 || dollars100 > -1) {
        setInventoryData({ flight, dollars25, dollars50, dollars100 });
        setTotalQty(totalQuantity);
        setSubmitting(false);
      } else if (error && message) {
        setErrorMessage(message);
        setIsModal(true);
      } else if (error || code) {
        setErrorMessage("Something went wrong, please try again later");
        setIsModal(true);
      }
    } catch {
      setIsModal(true);
      setErrorMessage("Something went wrong, please try again later");
    }
    setIsModal(false);
    setSubmitting(false);
  };

  useEffect(() => {
    fetchData();
  }, [selectedDate]);

  return (
    <div className="wrapper">
      <Header isPrizeInventory={true} />
      {isModal && errorMessage && <PopUpCustom setIsModal={setIsModal}>{errorMessage}</PopUpCustom>}

      {submitting && <Loading />}
      {prizeInventoryDates.length > 0 && (
        <section className="section">
          <div className="shell">
            <div className="section__inner">
              <div className="section__head">
                <h2>Prize inventory</h2>
                <p>
                  Select the date to view the current prize inventory set for the event. Click the
                  button below to update the inventory for an event date.
                </p>
              </div>
              <div className="section__body">
                <div className="form form--select">
                  <form action="?" method="post">
                    <div className="form__body">
                      <div className="form__row">
                        <label htmlFor="select" className="form__label">
                          Select date
                        </label>
                        <div className="form__controls">
                          <div className="select">
                            <select
                              value={selectedDate}
                              style={{ color: "black" }}
                              onChange={(e) => {
                                e.preventDefault();
                                setSelectedDate(e.currentTarget.value);
                              }}>
                              {prizeInventoryDates.map((d) => (
                                <option key={d} value={d}>
                                  {d}
                                </option>
                              ))}
                            </select>
                          </div>
                          {/* /.select */}
                        </div>
                        {/* /.form__controls */}
                      </div>
                      {/* /.form__row */}
                    </div>
                    {/* /.form__body */}
                  </form>
                </div>
                {/* /.form */}
                <div className="table">
                  <table>
                    <thead>
                      <tr>
                        <th>Prize type</th>
                        <th>Today Qty</th>
                        <th style={{ border: "1px solid #15161C" }}>Remaining Qty</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>WestJet Flight</td>
                        <td>{inventoryData?.flight}</td>

                        <td style={{ border: "1px solid #15161C" }}>{totalQty?.flight}</td>
                      </tr>
                      <tr>
                        <td>$25 WestJet Dollars</td>
                        <td>{inventoryData?.dollars25}</td>

                        <td style={{ border: "1px solid #15161C" }}>{totalQty?.dollars25}</td>
                      </tr>
                      <tr>
                        <td>$50 WestJet Dollars</td>
                        <td>{inventoryData?.dollars50}</td>

                        <td style={{ border: "1px solid #15161C" }}>{totalQty?.dollars50}</td>
                      </tr>
                      <tr>
                        <td>$100 WestJet Dollars</td>
                        <td>{inventoryData?.dollars100}</td>

                        <td style={{ border: "1px solid #15161C" }}>{totalQty?.dollars100}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* /.table */}
              </div>
              <div className="section__actions">
                <button
                  className={
                    dayjs(selectedDate).add(1, "day").isBefore(dayjs())
                      ? " form__btn btn_disable"
                      : "btn"
                  }
                  onClick={() => navigate("/portal/inventory", { state })}
                  disabled={dayjs(selectedDate).add(1, "day").isBefore(dayjs()) && true}>
                  Update Inventory
                </button>
              </div>{" "}
            </div>
            {/* /.section__inner */}
          </div>
          {/* /.shell */}
        </section>
      )}

      {/* /.section */}
      <Footer />
    </div>
  );
}

export default InventoryPrize;
