import { FunctionComponent } from "react";

interface FooterProps {}

const Footer: FunctionComponent<FooterProps> = () => {
  return (
    <footer className="footer footerBottom">
      <div className="shell">
        <div className="footer__inner">
          <p className="footer__copyright">© WestJet. All rights reserved.</p>
          {/* /.footer__copyright */}

          {/* /.copyright */}
        </div>
        {/* /.footer__inner */}
      </div>
      {/* /.shell */}
    </footer>
  );
};

export default Footer;
