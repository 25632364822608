import { FunctionComponent, useState } from "react";
import { useStores } from "../../stores";
import { Link, useNavigate } from "react-router-dom";
import PopUpCustom from "../PopUpCustom";

interface HeaderProps {
  isPrizeInventory?: boolean;
}

const Header: FunctionComponent<HeaderProps> = ({ isPrizeInventory }) => {
  const { authStore } = useStores();
  const [isModal, setIsModal] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const setCancel = () => setIsModal(false);

  const logOut = async () => {
    setIsModal(false);
    try {
      await authStore.logout();

      navigate("/portal", { replace: true });
    } catch (error) {
      console.error("error signing out: ", error);
    }
  };
  return (
    <header className="header">
      {isModal && (
        <PopUpCustom setIsModal={logOut} setCancel={setCancel}>
          Are you sure, you would like to Logout?
        </PopUpCustom>
      )}
      <div className="shell">
        <div className="header__inner">
          <a href="#" className="logo">
            <img src="/assets/images/svg/logo-white.svg" width="165" height="34" alt="" />
          </a>

          <div className={menuOpen ? "header__nav js-nav  is-open" : "header__nav js-nav"}>
            <nav className="nav">
              <ul>
                <li
                  className={!isPrizeInventory ? "is-current" : ""}
                  onClick={() => {
                    setMenuOpen(false);
                  }}>
                  <Link to="/portal/prize-search">Prize Redemption</Link>
                </li>

                <li
                  className={isPrizeInventory ? "is-current" : ""}
                  onClick={() => {
                    setMenuOpen(false);
                  }}>
                  <Link to="/portal/inventory-prize">Prize Inventory</Link>
                </li>

                <li
                  onClick={() => {
                    setMenuOpen(false);
                  }}>
                  <a onClick={() => setIsModal(true)}>Logout</a>
                </li>
              </ul>
            </nav>
          </div>
          <a
            onClick={() => {
              setMenuOpen(!menuOpen);
            }}
            className={
              menuOpen ? "nav-trigger js-nav-trigger is-open" : "nav-trigger js-nav-trigger"
            }>
            <span></span>
            <span></span>
            <span></span>
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
